import React, { Component } from 'react'
import styles from './css/afzallik.module.css'
import {Container,Row,Col} from 'react-bootstrap'
import {DeploymentUnitOutlined,EnvironmentOutlined,DollarCircleOutlined,UserOutlined,CheckOutlined,FileDoneOutlined,BranchesOutlined ,ConsoleSqlOutlined   } from '@ant-design/icons'

export default class Afzallik1Ru extends Component {
    render() {
        return (
            <div>
                <Container fluid className={styles.afzalliklar}>
                <Row>
                    <Col lg={42}>
                    <h1>Наши преимущества</h1>
                    </Col>
                    <Col  xl={4} lg={4} md={6} sm={12}>
                        <div className={styles.cardAfzalliklar}>
                            <div>
                            <DeploymentUnitOutlined style={{fontSize:'40px',color:'white'}}/>
                            
                            </div>
                            <div>
    
                                <h2>АО «Нефтегазтадкикот» имеет международно-аккредитованную исследовательскую лабораторию.</h2>
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}  lg={4} md={6} sm={12}>
                        <div className={styles.cardAfzalliklar}>
                            <div>
                            <EnvironmentOutlined style={{fontSize:'40px',color:'white'}}/>
                            
                            </div>
                            <div>
    
                                <h2>Высокая мобильность исследовательских коллективов с учетом географического положения предприятия (команда может добраться до объекта заказа в течение одного рабочего дня).</h2>
                            </div>
                        </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12}>
                        <div className={styles.cardAfzalliklar}>
                            <div>
                            <DollarCircleOutlined style={{fontSize:'40px',color:'white'}}/>                        
                                                   
                            </div>
                            <div>
    
                                <h2>Оптимальные цены на исследования и разработки.</h2>
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}  lg={4} md={6} sm={12}>
                        <div className={styles.cardAfzalliklar}>
                            <div>
                            <UserOutlined style={{fontSize:'40px',color:'white'}}/>
                            
                            </div>
                            <div>
    
                                <h2>Наличие высококвалифицированных специалистов в сфере деятельности.</h2>
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}  lg={4} md={6} sm={12}>
                        <div className={styles.cardAfzalliklar}>
                            <div>
                            <FileDoneOutlined style={{fontSize:'40px',color:'white'}}/>
                            
                            </div>
                            <div>
    
                                <h2>Международно сертифицированное высококачественное исследование.</h2>
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}  lg={4} md={6} sm={12}>
                        <div className={styles.cardAfzalliklar}>
                            <div>
                            <CheckOutlined style={{fontSize:'40px',color:'white'}}/>
                            
                            </div>
                            <div>
    
                                <h2>Возможность проведения узких профилактических исследований, таких как тестирование ингибиторов коррозии и цеолитов, импортируемых в Республику Узбекистан.</h2>
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}  lg={4} md={6} sm={12}>
                        <div className={styles.cardAfzalliklar}>
                            <div>
                            <BranchesOutlined style={{fontSize:'40px',color:'white'}}/>
                            
                            </div>
                            <div>
    
                                <h2>Большой опыт работы с "Узбекнефтегазом" и иностранными компаниями.</h2>
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}  lg={4} md={6} sm={12}>
                        <div className={styles.cardAfzalliklar}>
                            <div>
                            <ConsoleSqlOutlined  style={{fontSize:'40px',color:'white'}}/>
                            
                            </div>
                            <div>
    
                                <h2>Наличие огромной базы данных и опыта проведения исследований ....</h2>
                            </div>
                        </div>
                    </Col>
                </Row>
                </Container>
            </div>
        )
    }
}
