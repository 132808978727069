import React, { Component } from 'react'
// import style1 from '../css/App.module.css'
import HashLoader from "react-spinners/HashLoader";
import style from '../css/Strategiya.module.css'
export default class Strategiya extends Component {
    state={
        loading:true,
    }  
    
    componentDidMount(){
        this.setState({
            loading:false
        })
    }
    render() {
        return (
            <div>
                 {
              this.state.loading?<div  style={{position:"fixed", zIndex:'344322', backgroundColor:'white', display:'flex', justifyContent:'center', alignItems:'center', top:'0px', left:'0px', width:'100%', height:'100%'}} >

              <HashLoader color="blue" loading={this.state.loading} size={100} css={{zIndex:'432323'}}/> 
                          
                      </div>:
                      <div className={style.back}>
                          <div className={style.qora}></div>
<p className={style.p}>
    <h4>Стратегия развития ОАО «Нефтегазовые исследования»</h4>
    <p>В настоящее время ОАО «Нефтегазовые исследования» активно расширяет свои возможности и улучшает качество исследований. Чтобы полностью удовлетворить потребности существующих и будущих партнеров, компания открывает новые направления в своей деятельности.</p>
    <p>Для достижения поставленных целей стратегические цели ОАО «Нефтгазтадкикот» заключаются в развитии по следующим направлениям:</p>
<ul>
<li>Повышение точности и качества результатов исследований с использованием современных инновационных технологий;</li>
<li>Поддержка здоровой конкурентной среды в исследованиях в нефтегазовом секторе Республики Узбекистан;</li>
<li>Развитие применения информационно-коммуникационных технологий при проведение исследований и поэтапная автоматизация процесса;</li>
<li>Подготовка и повышение квалификации инженерно-технического персонала общества;</li>
<li>Активное привлечения потенциальных и иностранных инвесторов к сотрудничеству;</li>
</ul>
</p>

                      </div>
                      
                      
                      }
            </div>
        )
    }
}
