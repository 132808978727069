import React, { Component } from 'react'
import style from '../css/Dvident.module.css'


export default class DvidentRu extends Component{

render(){
  return(
<div className={style.mat}>
    <h2 className={style.sar}>Выплаченные доходы по ценным бумагам в процентах
к номинальной стоимости по результатам:</h2>
	<div className="blueLine"></div>
	<div className={style.container1}>  
        <div className={style.pricingBox}>
        <div className={style.win}>
		<h5  style={{color:'white'}}>Выплаты по видам ценных бумаг</h5 >
		<p className={style.price}>По простым акциям</p>
		<ul className={style.featuresList}>
			<li style={{fontSize:'19px'}}><strong>2015 год</strong><span>........</span>..... 8.67% </li>
			<li style={{fontSize:'19px'}}><strong>2016 год</strong><span>........</span>..... 8.67% </li>
			<li style={{fontSize:'19px'}}><strong>2017 год</strong><span>........</span>..... 8.67% </li>
			<li style={{fontSize:'19px'}}><strong>2018 год</strong><span>........</span>..... 8.67% </li>
			<li style={{fontSize:'19px'}}><strong>2019 год</strong><span>........</span>..... 10% </li>
			<li style={{fontSize:'19px'}}><strong>2020 год</strong><span>........</span>..... 10% </li>
			<li style={{fontSize:'19px'}}><strong>2021 год</strong><span>........</span>..... 10% </li>
			<li style={{fontSize:'19px'}}><strong>2022 год</strong><span>........</span>..... 10% </li>
		</ul>
        </div>
	</div>
    <div className={style.pricingBox1}>
        <div className={style.win}>
		<h5  style={{color:'white'}}>Выплаты по видам ценных бумаг</h5>
		<p className={style.price}>По привилегированным акциям</p>
		<ul className={style.featuresList}>
			<li style={{fontSize:'19px'}}><strong>2015 год</strong><span>........</span>..... 8.67% </li>
			<li style={{fontSize:'19px'}}><strong>2016 год</strong><span>........</span>..... 8.67% </li>
			<li style={{fontSize:'19px'}}><strong>2017 год</strong><span>........</span>..... 8.67% </li>
			<li style={{fontSize:'19px'}}><strong>2018 год</strong><span>........</span>..... 8.67% </li>
			<li style={{fontSize:'19px'}}><strong>2019 год</strong><span>........</span>..... 10% </li>
			<li style={{fontSize:'19px'}}><strong>2020 год</strong><span>........</span>..... 10% </li>
			<li style={{fontSize:'19px'}}><strong>2021 год</strong><span>........</span>..... 10% </li>
			<li style={{fontSize:'19px'}}><strong>2022 год</strong><span>........</span>..... 10% </li>
		</ul>
        </div>
	</div>
	</div>
    </div>
  )
}
}