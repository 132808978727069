import React, { Component } from 'react'
import {Card, Button} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import style from '../css/Ijro.module.css'
import image from '../img/pl.png'
export default class KengashRu extends Component{


render(){
  return(
	<div className={style.tana}>
        <h4 className={style.sarl}>Список всех членов наблюдательного совета эмитента</h4>
	<div className="blueLine"></div>
          <p align="center">(по состоянию на 01 июля 2020 г.)</p>
<Card className={style.qart}>
  <Card.Header as="h5" className={style.ism}>Турдибаев Алишер Абдувасикович</Card.Header>
  <Card.Body className={style.cBody}>
      <div className={style.rasm}>
        <img src={image}/>
        <h6>Турдибаев Алишер Абдувасикович</h6>
      </div>
      <div className={style.tavsif}><Card.Title><b>Заместитель председателя правления АО «Худудгазтаъминот»</b><br/>
      </Card.Title>
    <Card.Text>
        <br/>
        <p className={style.ulush} className={style.ulush1}><b>
        Доля в уставном капитале эмитента: </b>0 штуг</p>
        <p className={style.ulush}><b>
        доля в уставном фонде: </b>0 %</p>
    </Card.Text>
    </div>
  </Card.Body>
</Card>
<Card className={style.qart}>
  <Card.Header as="h5" className={style.ism}>Аҳметов Истам Ҳамроевич</Card.Header>
  <Card.Body className={style.cBody}>
      <div className={style.rasm}>
        <img src={image}/>
        <h6>Аҳметов Истам Ҳамроевич</h6>
      </div>
      <div className={style.tavsif}><Card.Title><b>Учредитель семейного предприятия "IDEAL DIZAYN"</b><br/>
      </Card.Title>
    <Card.Text>
        <br/>
        <p className={style.ulush} className={style.ulush1}><b>
        Доля в уставном капитале эмитента: </b>0 штуг</p>
        <p className={style.ulush}><b>
        доля в уставном фонде: </b>0 %</p>
    </Card.Text><b></b> 
    </div>
  </Card.Body>
</Card>
<Card className={style.qart}>
  <Card.Header as="h5" className={style.ism}>Ҳафизов Умиджон Усмонович</Card.Header>
  <Card.Body className={style.cBody}>
      <div className={style.rasm}>
        <img src={image}/>
        <h6>Ҳафизов Умиджон Усмонович</h6>
      </div>
      <div className={style.tavsif}><Card.Title><b>Начальник отдела финансового и комплаенс-контроля акционерного общества «УЗНЕФТГАЗКУДУКТАМИРЛАШ»</b><br/>
      </Card.Title>
    <Card.Text>
    <p className={style.ulush} className={style.ulush1}><b>
        Доля в уставном капитале эмитента: </b>0 штуг</p>
        <p className={style.ulush}><b>
        доля в уставном фонде: </b>0 %</p>
    </Card.Text><b></b> 
    </div>
  </Card.Body>
</Card>
<Card className={style.qart}>
  <Card.Header as="h5" className={style.ism}>Абдуллаев Тимур Русланович</Card.Header>
  <Card.Body className={style.cBody}>
      <div className={style.rasm}>
        <img src={image}/>
        <h6>Абдуллаев Тимур Русланович</h6>
      </div>
      <div className={style.tavsif}><Card.Title><b>Административный директор «Enter Engineering PTE Ltd»</b><br/>
      </Card.Title>
    <Card.Text>
        <br/>
        <p className={style.ulush} className={style.ulush1}><b>
        Доля в уставном капитале эмитента: </b>0 штуг</p>
        <p className={style.ulush}><b>
        доля в уставном фонде: </b>0 %</p>
    </Card.Text><b></b> 
    </div>
  </Card.Body>
</Card>
<Card className={style.qart}>
  <Card.Header as="h5" className={style.ism}>Собиров Бахтиёр Махмудович</Card.Header>
  <Card.Body className={style.cBody}>
      <div className={style.rasm}>
        <img src={image}/>
        <h6>Собиров Бахтиёр Махмудович</h6>
      </div>
      <div className={style.tavsif}><Card.Title><b>Директор Дома приемов официальных гостей 
          Бухарского областного хокимията</b><br/>
      </Card.Title>
    <Card.Text>
        <br/>
        <p className={style.ulush} className={style.ulush1}><b>
        Доля в уставном капитале эмитента: </b>0 штуг</p>
        <p className={style.ulush}><b>
        доля в уставном фонде: </b>0 %</p>
    </Card.Text><b></b> 
    </div>
  </Card.Body>
</Card>
	</div>
  )
}
}