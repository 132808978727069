import React, { Component } from 'react'
import {Card, Button} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import style from '../css/Ijro.module.css'
import image from '../img/pl.png'
export default class IjroRu extends Component{

  
 
 
render(){
  return(
	<div className={style.tana}>
        <h4 className={style.sarl}>Список всех членов исполнительного органа управления:</h4>
        <div className="blueLine"></div>
<Card className={style.qart}>
  <Card.Header as="h5" className={style.ism}>Солиев Элёр Элмуродович</Card.Header>
  <Card.Body className={style.cBody}>
      <div className={style.rasm}>
        <img src={image}/>
        <h6>Солиев Элёр Элмуродович</h6>
      </div>
      <div className={style.tavsif}><Card.Title><br/>
      </Card.Title>
    <Card.Text>
       <i style={{fontSize:'18px'}}>Председатель правления</i>
        <br/>
        
        <p className={style.ulush} className={style.ulush1}><b>
        Доля в уставном капитале эмитента: </b>0 штуг</p>
        <p className={style.ulush}><b>
        доля в уставном фонде: </b>0 %</p>
    </Card.Text><b></b> 
    </div>
  </Card.Body>
</Card>
<Card className={style.qart}>
  <Card.Header as="h5" className={style.ism}>Хусанов Фархад Рустамович</Card.Header>
  <Card.Body className={style.cBody}>
      <div className={style.rasm}>
        <img src={image}/>
        <h6>Хусанов Фархад Рустамович</h6>
      </div>
      <div className={style.tavsif}><Card.Title><br/>
      </Card.Title>
    <Card.Text>
       <i style={{fontSize:'18px'}}>Экономист, заместитель главного бухгалтера</i>
        <br/>
        <p className={style.ulush} className={style.ulush1}><b>
        Доля в уставном капитале эмитента: </b>0 штуг</p>
        <p className={style.ulush}><b>
        доля в уставном фонде: </b>0 %</p>
    </Card.Text><b></b> 
    </div>
  </Card.Body>
</Card>
<Card className={style.qart}>
  <Card.Header as="h5" className={style.ism}>Тошов Нурхон Мирзоевич</Card.Header>
  <Card.Body className={style.cBody}>
      <div className={style.rasm}>
        <img src={image}/>
        <h6>Тошов Нурхон Мирзоевич</h6>
      </div>
      <div className={style.tavsif}><Card.Title><br/>
      </Card.Title>
    <Card.Text>
        <i style={{fontSize:'18px'}}> Заместитель председателя правления</i>
        <br/>
        
        <p className={style.ulush} className={style.ulush1}><b>
        Доля в уставном капитале эмитента: </b>0 штуг</p>
        <p className={style.ulush}><b>
        доля в уставном фонде: </b>0 %</p>
    </Card.Text><b></b> 
    </div>
  </Card.Body>
</Card>
	</div>
  )
}
}