import React, { Component } from 'react'
import flag from '../img/flag.png'
import logo from '../img/LOGO_NGI.png'
import {NavLink} from 'react-router-dom'
export default class Footer extends Component {
    render() {
        return (
            <div>
{
    window.location.href.split('/')[window.location.href.split('/').length-1]==='uz' || window.location.href.split('/')[window.location.href.split('/').length-1]===''?
    <div class="page-wrapper" style={{backgroundColor:'white', marginTop:'0px'}}>
   
    <footer >
      <div class="footer-top" >
      <img className='flag' src={flag}/>
        <div class="pt-exebar">

        </div>
        <div class="container">
          <div class="row">
        
            <div class="col-lg-3 col-md-12 col-sm-12 footer-col-3">
              <div class="widget footer_widget">
                <h5 class="footer-title manzil">Manzil</h5>
                <div class="gem-contacts">
                  <div class="gem-contacts-item gem-contacts-address">Bosh office : 200118, Buxoro shahar, Islom Karimov ko'chasi , 1
                  </div>
                  <div class="gem-contacts-item gem-contacts-phone"><i class="fa fa-phone" aria-hidden="true"></i> Telefon nomer: <a  href="tel:+998-65-221-20-20">+998-65-221-20-20</a></div>
                  <div class="gem-contacts-item gem-contacts-phone"><i class="fa fa-phone" aria-hidden="true"></i> Faks nomer: <a  href="">+998-65-221-20-20</a></div>

                  <div class="gem-contacts-item gem-contacts-phone"><i class="fa fa-email" aria-hidden="true"></i> Email <a >info@aongi.uz</a></div>

                </div>
              </div>

            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="row">
                <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                  <div class="widget footer_widget">
                    <h5 class="footer-title">Sahifalar</h5>
                    <ul class="posts  styled">
                      <li class="clearfix gem-pp-posts">
                        <div class="gem-pp-posts-text">
                          <div class="gem-pp-posts-item">
                           <NavLink  to="/bugalteriya/uz">
          Ochiq ma'lumotlar
          </NavLink>        
                          </div>
                          
                        </div>
                      </li>
                      <li class="clearfix gem-pp-posts">
                        <div class="gem-pp-posts-text">
                          <div class="gem-pp-posts-item">
                           <NavLink  to="/konkurs/uz">
        Konkurslar 
        </NavLink>
                          </div>
                          
                        </div>
                      </li>
                      <li class="clearfix gem-pp-posts">
                        <div class="gem-pp-posts-text">
                          <div class="gem-pp-posts-item">
                           <NavLink  to="/elon/uz">
          E'lonlar
          </NavLink>
                          </div>
                          
                        </div>
                      </li>
                      <li class="clearfix gem-pp-posts">
                        <div class="gem-pp-posts-text">
                          <div class="gem-pp-posts-item">
                           <NavLink  to="/aloqalar/uz">
          Aloqalar
          </NavLink>
                          </div>
                          
                        </div>
                      </li>

                    </ul>
                  </div>
                </div>
                <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                  <div class="widget">
                    <h5 class="footer-title">Email yuborish</h5>
                    <div class="textwidget">
                      <div role="form" class="wpcf7" id="wpcf7-f4-o1" lang="en-US" dir="ltr">

                        <form method="post" class="wpcf7-form" novalidate="novalidate">

                          <div class="contact-form-footer">
                            <p><span class="wpcf7-form-control-wrap your-first-name"><input type="text" name="your-first-name" value="" size="40" class="wpcf7-form-control wpcf7-text" aria-invalid="false" placeholder="Ism familiyangiz"/></span></p>
                            <p><span class="wpcf7-form-control-wrap your-email_1"><input type="email" name="your-email_1" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email" aria-invalid="false" placeholder="Email manzilingiz"/></span></p>
                            <p><span class="wpcf7-form-control-wrap your-message"><textarea name="your-message" cols="40" rows="10" class="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="Xabar..."></textarea></span></p>
                            <div><input type="submit" value="Yuborish" class="wpcf7-form-control wpcf7-submit"/><span class="ajax-loader"></span></div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  </div></div>
                  </div>
                <div class="col-lg-3 col-md-6 col-sm-12 footer-col-4 logoForm">
              <div class="widget widget_gallery ">
             
                <div style={{width:'140%', height:'280px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <img src={logo} style={{width:'200px'}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
    </footer>
  </div>:
 
 //ruschasi

 <div class="page-wrapper" style={{backgroundColor:'white', marginTop:'0px'}}>
   
 <footer >
   <div class="footer-top" >
   <img className='flag' src={flag}/>
     <div class="pt-exebar">

     </div>
     <div class="container">
       <div class="row">
     
         <div class="col-lg-3 col-md-12 col-sm-12 footer-col-3">
           <div class="widget footer_widget">
             <h5 class="footer-title manzil">Адрес</h5>
             <div class="gem-contacts">
               <div class="gem-contacts-item gem-contacts-address">Головной офис : 200118, город Бухара, улица Ислама Каримова, 1
               </div>
               <div class="gem-contacts-item gem-contacts-phone"><i class="fa fa-phone" aria-hidden="true"></i> Телефонный номер: <a  href="tel:+998-65-221-20-20">+998-65-221-20-20</a></div>
               <div class="gem-contacts-item gem-contacts-phone"><i class="fa fa-phone" aria-hidden="true"></i> Номер факса: <a  href="">+998-65-221-20-20</a></div>

               <div class="gem-contacts-item gem-contacts-phone"><i class="fa fa-email" aria-hidden="true"></i> Электронное письмо: <a >info@aongi.uz</a></div>

             </div>
           </div>

         </div>
         <div class="col-lg-6 col-md-6 col-sm-12">
           <div class="row">
             <div class="col-6 col-lg-6 col-md-6 col-sm-6  formNews">
               <div class="widget footer_widget">
                 <h5 class="footer-title">Страницы</h5>
                 <ul class="posts  styled">
                 <li class="clearfix gem-pp-posts">
                     <div class="gem-pp-posts-text">
                       <div class="gem-pp-posts-item">
                        <NavLink  to="/bugalteriya/ru">
                        Открытые данные
          </NavLink>        
                       </div>
                       
                     </div>
                   </li>
                   <li class="clearfix gem-pp-posts">
                     <div class="gem-pp-posts-text">
                       <div class="gem-pp-posts-item">
                        <NavLink  to="/konkurs/ru">
                        Тендеры 
        </NavLink>
                       </div>
                       
                     </div>
                   </li>
                   <li class="clearfix gem-pp-posts">
                     <div class="gem-pp-posts-text">
                       <div class="gem-pp-posts-item">
                        <NavLink  to="/elon/ru">
                        Объявления
          </NavLink>
                       </div>
                       
                     </div>
                   </li>
                   <li class="clearfix gem-pp-posts">
                     <div class="gem-pp-posts-text">
                       <div class="gem-pp-posts-item">
                         <NavLink  to="/aloqalar/ru">
                         Контакты
          </NavLink>
                       </div>
                       
                     </div>
                   </li>

                 </ul>
               </div>
             </div>
             <div class="col-6 col-lg-6 col-md-6 col-sm-6">
               <div class="widget">
                 <h5 class="footer-title">Отправить письмо</h5>
                 <div class="textwidget">
                   <div role="form" class="wpcf7" id="wpcf7-f4-o1" lang="en-US" dir="ltr">

                     <form method="post" class="wpcf7-form" novalidate="novalidate">

                       <div class="contact-form-footer">
                         <p><span class="wpcf7-form-control-wrap your-first-name"><input type="text" name="your-first-name" value="" size="40" class="wpcf7-form-control wpcf7-text" aria-invalid="false" placeholder="Ваше имя и фамилия"/></span></p>
                         <p><span class="wpcf7-form-control-wrap your-email_1"><input type="email" name="your-email_1" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email" aria-invalid="false" placeholder="Ваша электронная почта"/></span></p>
                         <p><span class="wpcf7-form-control-wrap your-message"><textarea name="your-message" cols="40" rows="10" class="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="Сообщение..."></textarea></span></p>
                         <div><input type="submit" value="Отправить" class="wpcf7-form-control wpcf7-submit"/><span class="ajax-loader"></span></div>
                       </div>
                     </form>
                   </div>
                 </div>
               </div>
               </div></div>
               </div>
             <div class="col-lg-3 col-md-6 col-sm-12 footer-col-4 logoForm">
           <div class="widget widget_gallery ">
           
             <div style={{width:'140%', height:'280px', display:'flex', justifyContent:'center', alignItems:'center'}}>
             <img src={logo} style={{width:'200px'}}/>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>

 </footer>
</div>

}
                   </div>
        )
    }
}
