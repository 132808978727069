import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { MDBCard, MDBCardTitle, MDBCardText, MDBCardBody, MDBCardHeader, MDBCardFooter, MDBCardImage, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import styles from '../css/komp_haqida.module.css'
import toshev from '../img/Toshov.jpg'

class BoshqaruvRu extends React.Component {
   

    render() {
        return (
            <div>
           
              <Container fluid className='mt-5 ' >
               
               <Row>
                
                 <Col lg={12}>
                 <h3 className='text-center mb-5'>Информация об исполнительном органе компании</h3>
                 <div className="blueLine"></div>
                 <MDBCard  className={styles.card} style={{ maxWidth: '100%', margin:'30px' }}>
      <MDBRow className='g-0'>
        <MDBCol md='5'>
          <MDBCardImage className={styles.rasm} src='https://image.freepik.com/free-photo/serious-businessman-using-laptop-cafe_74855-1221.jpg' alt='...' fluid />
        </MDBCol>
        <MDBCol md='7' >
          <MDBCardBody className={styles.cardbody}>
            <MDBCardTitle><h5><b>Член исполнительного органа</b></h5></MDBCardTitle>
            <div style={{ border:'1px solid blue', margin:'20px 10px 20px 0px' }}></div>
            <MDBCardText>
            <b style={{fontSize:'18px'}}>Ф.I.O. :</b><span style={{fontSize:'16px'}}>  Солиев Эльёр Эльмуродович </span><br/> 
            <b style={{fontSize:'18px'}}>Должность :</b>  <span style={{fontSize:'16px'}}>вр.и.о. Председателя правления</span> <br/> 
            
            <b style={{fontSize:'18px'}}>Информация:</b>  <span style={{fontSize:'16px'}}>Высокая, 2011- год, Бухарский Государственный Университет</span><br/> 
            <b style={{fontSize:'18px'}}>Специальность:</b><span style={{fontSize:'16px'}}>Экономика</span>   <br/> 
             <b style={{fontSize:'18px'}}>Телефонный номер:</b><span style={{fontSize:'16px'}}>+998930820372</span>   <br/> 
          <b style={{fontSize:'18px'}}>День приема :</b><span style={{fontSize:'16px'}}>по понедельникам</span>  <br/> 
          <b style={{fontSize:'18px'}}>Часы приема :</b><span style={{fontSize:'16px'}}> с  8<sup>00</sup>по 11<sup>00</sup> </span>
            </MDBCardText>
          </MDBCardBody>
        </MDBCol>
      </MDBRow>
    </MDBCard>

    <MDBCard className={styles.card} style={{ maxWidth: '100%', margin:'30px' }}>
      <MDBRow className='g-0'>
        <MDBCol md='7'>
          <MDBCardBody>
            <MDBCardTitle><h5><b>Член исполнительного органа</b><span style={{fontSize:'16px'}}></span></h5></MDBCardTitle>
            <div style={{ border:'1px solid blue', margin:'20px 10px 20px 0px' }}></div>
            <MDBCardText>
            <b style={{fontSize:'18px'}}>Ф.I.O. :</b><span style={{fontSize:'16px'}}>Тошев Нурхон Мирзаевич</span>   <br/> 
            <b style={{fontSize:'18px'}}>Должность :</b><span style={{fontSize:'16px'}}>Заместитель председателя правления</span>   <br/> 
            
            <b style={{fontSize:'18px'}}>Информация:</b><span style={{fontSize:'16px'}}>Высокая, 2016- год, Бухарский институт инженерных технологий</span>  <br/> 
            <b style={{fontSize:'18px'}}>Специальность:</b><span style={{fontSize:'16px'}}>Нефтегазохимическая технология</span>   <br/> 
             <b style={{fontSize:'18px'}}>Телефонный номер:</b><span style={{fontSize:'16px'}}>+998930820372</span>   <br/> 
          <b style={{fontSize:'18px'}}>День приема :</b><span style={{fontSize:'16px'}}>по средам </span>  <br/> 
          <b style={{fontSize:'18px'}}>Часы приема :</b><span style={{fontSize:'16px'}}> с 8<sup>00</sup> по 11<sup>00</sup> </span>
            </MDBCardText>
          </MDBCardBody>
        </MDBCol>
        <MDBCol md='5'>
          <MDBCardImage className={styles.rasm} src={toshev} alt='...' fluid />
        </MDBCol>
      </MDBRow>
    </MDBCard>

    <MDBCard className={styles.card} style={{ maxWidth: '100%', margin:'30px' }}>
      <MDBRow className='g-0'>
        <MDBCol md='5'>
          <MDBCardImage className={styles.rasm} src='https://image.freepik.com/free-photo/serious-businessman-using-laptop-cafe_74855-1221.jpg' alt='...' fluid />
        </MDBCol>
        <MDBCol md='7'>
          <MDBCardBody>
            <MDBCardTitle><h5><b>Член исполнительного органа</b><span style={{fontSize:'16px'}}></span></h5></MDBCardTitle>
            <div style={{ border:'1px solid blue', margin:'20px 10px 20px 0px' }}></div>
            <MDBCardText>
            <b style={{fontSize:'18px'}}>Ф.I.O. :</b><span style={{fontSize:'16px'}}>Хусанов Фарход Рустамович</span>   <br/> 
            <b style={{fontSize:'18px'}}>Должность :</b><span style={{fontSize:'16px'}}>Главный бухгалтер</span>   <br/> 
            
            <b style={{fontSize:'18px'}}>Информация:</b><span style={{fontSize:'16px'}}>Высокая, 2004- год, Бухарский институт технологий пищевой и легкой промышленности</span>  <br/> 
            <b style={{fontSize:'18px'}}>Специальность:</b><span style={{fontSize:'16px'}}>Управление</span>   <br/> 
             <b style={{fontSize:'18px'}}>Телефонный номер:</b><span style={{fontSize:'16px'}}>+998930820372</span>   <br/> 
          <b style={{fontSize:'18px'}}>День приема :</b><span style={{fontSize:'16px'}}>по пятницам </span>  <br/> 
          <b style={{fontSize:'18px'}}>Часы приема :</b><span style={{fontSize:'16px'}}> с  8<sup>00</sup> по 11<sup>00</sup></span> 
            </MDBCardText>
          </MDBCardBody>
        </MDBCol>
      </MDBRow>
    </MDBCard>
                 </Col>
               </Row>
              
              </Container>
            </div>
        );
    }
}



export default BoshqaruvRu;
