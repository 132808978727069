import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import tuzilmaRu from '../img/tuzilmaRu.jpg'
import styles from '../css/komp_haqida.module.css'

class Tashkiliy_tuzilmaRu extends React.Component {


    render() {
        return (
            <div>
                <Container  className='mt-5 '>
               <Row>
                   <Col lg={1}></Col>
                   <Col lg={10}>
                   <h3 className='text-center mb-5'>Организационная структура ОАО «Нефтгазтадкикот»</h3>
                   <div className="blueLine" style={{marginBottom:'20px'}}></div>
                <img className={styles.tuzilma} src={tuzilmaRu} style={{marginLeft:'auto', marginRight:'auto', display:'block'}} />
               <div className={styles.text}>
               <p  style={{textIndent:'25px', textAlign:'justify'}}>
               Высшим органом управления АО «Нефтегазисследование» является Общее собрание акционеров.
                </p>
               <p style={{textIndent:'25px', textAlign:'justify'}}>
               Наблюдательный совет, избираемый Общим собранием акционеров, осуществляет общее руководство деятельностью общества, определяет приоритетные направления деятельности, стратегию и перспективы развития, вырабатывает тактику и среднесрочные задачи, стоящие перед АО «Нефтегазисследование».
               </p>
                 <p style={{textIndent:'25px', textAlign:'justify'}}>
                 Руководство текущей деятельностью осуществляется коллегиальным исполнительным органом – Правлением, который организует контроль и выполнение решений, принимаемых Общим собранием акционеров и Наблюдательным советом.
                 </p>
               </div>
                   </Col>
                   <Col lg={1}></Col>
               </Row>
                
                </Container>
            </div>
        );
    }
}



export default Tashkiliy_tuzilmaRu;
