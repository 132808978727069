import React, { Component } from 'react'
import style from '../css/Dvident.module.css'


export default class Dvident extends Component{

render(){
  return(
<div className={style.mat} style={{marginTop:'40px',}} >
    <h2 className={style.sar} style={{width:'80%', margin:'auto', marginBottom:'30px'}}>Qimmatbaxo qog'ozlar naminaliga nisbatan foiz xisobida to'langan daromadlar:</h2>
<br/>	<div className="blueLine"></div>
	<div className={style.container1}>  
        <div className={style.pricingBox}>
        <div className={style.win}>
		<h5 style={{color:'white'}}>Qimmatbaxo qog'oz</h5>
		<p className={style.price} >Oddiy aksiyalar bo'yicha</p>
		<ul className={style.featuresList}>
			<li><strong>2015 - yil</strong><span>........</span>..... 8.67% </li>
			<li><strong>2016 - yil</strong><span>........</span>..... 8.67% </li>
			<li><strong>2017 - yil</strong><span>........</span>..... 8.67% </li>
			<li><strong>2018 - yil</strong><span>........</span>..... 8.67% </li>
			<li><strong>2019 - yil</strong><span>........</span>..... 10% </li>
			<li><strong>2020 - yil</strong><span>........</span>..... 10% </li>
			<li><strong>2021 - yil</strong><span>........</span>..... 10% </li>
			<li><strong>2022 - yil</strong><span>........</span>..... 10% </li>
		</ul>
        </div>
	</div>
    <div className={style.pricingBox1}>
        <div className={style.win}>
		<h5 style={{color:'white'}}>Qimmatbaxo qog'oz</h5>
		<p className={style.price} >Imtiyozli aksiyalar bo'yicha</p>
		<ul className={style.featuresList}>
			<li><strong>2015 - yil</strong><span>........</span>..... 8.67% </li>
			<li><strong>2016 - yil</strong><span>........</span>..... 8.67% </li>
			<li><strong>2017 - yil</strong><span>........</span>..... 8.67% </li>
			<li><strong>2018 - yil</strong><span>........</span>..... 8.67% </li>
			<li><strong>2019 - yil</strong><span>........</span>..... 10% </li>
			<li><strong>2020 - yil</strong><span>........</span>..... 10% </li>
			<li><strong>2021 - yil</strong><span>........</span>..... 10% </li>
			<li><strong>2022 - yil</strong><span>........</span>..... 10% </li>
		</ul>
        </div>
	</div>
	</div>
    </div>
  )
}
}