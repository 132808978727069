import React, { Component } from 'react'
import styles from './css/homiy.module.css'
import neftgaz from '../../img/uzbekneftregaz-logo.png'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import muborak from '../../img/muborak-gpz-_1547124324.png'
import shortan from '../../img/shurtan-_1547123623.png'
import lukoyl from '../../img/lukoil-new_1547127704.png'
import muborak2 from '../../img/buhara-npz-_1547124860.png'
import ngs from '../../img/ngs.jpg'
import gng from '../../img/gng.jpg'

import gazli from '../../img/gazli.jpg'
import global from '../../img/global.jpg'
import utg from '../../img/utg.jpg'
import uzkorgaz from '../../img/uzkorgaz.jpg'
import jizzax from '../../img/jizzax.png'
import nsr from '../../img/nsr.png'
export default class Homiy1 extends Component {
   
    render() {
        const responsive = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 3000 },
              items: 5
            },
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 4
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1
            }
          }
        return (
            <div>
                <div className={styles.sliderHomiy}>
                    <h1>
                        Bizning hamkorlarimiz
                    </h1>
                    <Carousel responsive={responsive}
                    autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlaySpeed={3000}
                    infinite={true}
                    
                    
                    >
                            <div style={{backgroundColor:'white', transform:'translate(-5px, px)'}}>
                                <img src={neftgaz}/>
                            </div>
                            <div style={{backgroundColor:'white', transform:'translate(-5px, px)'}}>
                                <img src={utg}/>
                            </div>
                            <div style={{backgroundColor:'white', transform:'translate(-5px, px)'}}>
                                <img src={muborak}/>
                            </div>
                            <div style={{backgroundColor:'white', transform:'translate(-5px, px)'}}>
                                <img src={shortan}/>
                            </div>
                            <div style={{backgroundColor:'white', transform:'translate(-5px, px)'}}>
                                <img src={lukoyl}/>
                            </div>
                            <div style={{backgroundColor:'white', transform:'translate(-5px, px)'}}>
                                <img src={muborak2}/>
                            </div>
                             <div style={{backgroundColor:'white', transform:'translate(-5px, px)'}}>
                                <img src={ngs}/>
                            </div>
                            <div style={{backgroundColor:'white', transform:'translate(-5px, px)'}}>
                                <img src={gng}/>
                            </div>
                            <div style={{backgroundColor:'white', transform:'translate(-5px, px)'}}>
                                <img src={nsr}/>
                            </div>
                            <div style={{backgroundColor:'white', transform:'translate(-5px, px)'}}>
                                <img src={jizzax}/>
                            </div>
                            <div style={{backgroundColor:'white', transform:'translate(-5px, px)'}}>
                                <img src={gazli}/>
                            </div>
                           
                            <div style={{backgroundColor:'white', transform:'translate(-5px, px)'}}>
                                <img src={uzkorgaz}/>
                            </div>
                            
                            </Carousel>
                </div>
            </div>
        )
    }
}
