import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';

import styles from '../css/komp_haqida.module.css'
class Moliyaviy_korsatkich extends React.Component {
    

    render() {
        return (
            <div>
                <Container fluid className='mt-5 '>
                <Row>
			
					<Col lg={12}>
					<h3 className='text-center mb-5'>2018 - 2020 yil yakunlari bo‘yicha
«Neftgaztadqiqot» AJ asosiy moliyaviy-iqtisodiy ko‘rsatkichlari haqida
ma'lumot</h3>
<div className="blueLine" style={{marginBottom:'20px'}}></div>
	<Table  responsive="sm" responsive="md" responsive="lg" className={styles.jadval}>
		<thead >
			<tr>
				<th style={{textAlign:'center', fontSize:'18px'}}>Ko'rsatkichlar</th>
				<th style={{textAlign:'center', fontSize:'18px'}}>O'lchov bir.</th>
				<th colSpan="3" style={{textAlign:'center', fontSize:'18px'}}>Faoliyat natijalari</th>
				
			</tr>
            <tr >
                <th></th>
                <th></th>
                <th>2018-y.</th><th>2019-y.</th><th>2020-y.</th><th>2021-y.</th><th>2022-y.</th><th>2023-y.</th>
            </tr>
		</thead>
		<tbody >
			<tr>
				<td style={{color:'black', fontSize:'18px'}}><b>Jami daromad</b></td>
				<td style={{textAlign:'center'}}>mln. so'm</td>
				<td>5 986</td>
				<td>6 664</td>
				<td>11 174</td>
				<td>12 772</td>
				<td>13 044</td>
				<td>14 318</td>
			</tr>
			<tr>
				<td>Mahsulot sotishdan olinganidan sof tushum</td>
				<td style={{textAlign:'center'}}>mln. so'm</td>
				<td>5 986</td>
				<td>6 664</td>
				<td>11 174</td>
				<td>12 772</td>
				<td>13 044</td>
				<td>14 318</td>

			</tr>
			<tr>
				<td>Asosiy faoliyatdan boshqa daromadlar</td>
				<td style={{textAlign:'center'}}>mln. so'm</td>
				<td>40</td>
				<td>31</td>
				<td>353</td>
				<td>227</td>
				<td>81</td>
				<td>242</td>

			</tr>
			<tr>
				<td>Moliyaviy faolyatdan boshqa daromadlar</td>
				<td style={{textAlign:'center'}}>mln. so'm</td>
				<td>0</td>
				<td>0</td>
				<td>0</td>
				<td>0</td>
				<td>0</td>
				<td>0</td>
			</tr>
			<tr>
				<td style={{color:'black', fontSize:'18px'}}><b>Jami xarajatlar</b></td>
				<td style={{textAlign:'center'}}>mln. so'm</td>
				<td>2 615</td>
				<td>3 324</td>
				<td>6 141</td>
				<td>7 784</td>
				<td>7 841</td>
				<td>6 828</td>
			</tr>
            <tr>
				<td>Sotilgan mahsulotlar tan narxi</td>
				<td style={{textAlign:'center'}}>mln. so'm</td>
				<td>2 944</td>
				<td>2 889</td>
				<td>3 740</td>
				
			<td>4 336</td>
			<td>4 222</td>
			<td>6 526</td>
			</tr>














            <tr>
				<td>Davr xarajatlari</td>
				<td style={{textAlign:'center'}}>mln. so'm</td>
				<td>2 615</td>
				<td>3 324</td>
				<td>6 141</td>
			<td>7 784</td>
			<td>7 841</td>
			<td>6 828</td>
			</tr>
            <tr>
				<td>Moliyaviy faoliyat xarajatlari</td>
				<td style={{textAlign:'center'}}>mln. so'm</td>
				<td>0</td>
				<td>0</td>
				<td>0</td>
			<td>0</td>
			<td>0</td>
			<td>0</td>
			</tr>
            <tr>
				<td style={{color:'black', fontSize:'18px'}}><b>Daromad solig'i to'langanga qadar bo'lgan foyda</b></td>
				<td style={{textAlign:'center'}}>mln. so'm</td>
				<td>468</td>
				<td>481</td>
				<td>1 645</td>
			<td>879</td>
			<td>1 059</td>
			<td>1 205</td>
			</tr>
            <tr>
				<td style={{color:'black', fontSize:'18px'}}><b>Soliq va boshqa majburiy to'lovlar</b></td>
				<td style={{textAlign:'center'}}>mln. so'm</td>
				<td>3 058</td>
				<td>3 185</td>
				<td>3 024</td>
			<td>4 078</td>
			<td>160</td>
			<td>180</td>
			</tr>
            <tr>
				<td style={{color:'black', fontSize:'18px'}}><b>Sof foyda</b></td>
				<td style={{textAlign:'center'}}>mln. so'm</td>
				<td>345</td>
				<td>403</td>
				<td>1 381</td>
			<td>745</td>
			<td>900 180</td>
			<td>1 024</td>
			</tr>
            <tr>
				<td>Quvvatlar bandligi</td>
				<td style={{textAlign:'center'}}>%</td>
				<td>138</td>
				<td>154</td>
				<td>200</td>
			<td>218</td>
			<td>308</td>
			<td>317</td>
			</tr>
            <tr>
				<td>Jihozlar eskirishi darajasi</td>
				<td style={{textAlign:'center'}}>%</td>
				<td>93,7</td>
				<td>92,2</td>
				<td>89,8</td>
			<td>90,9</td>
			<td>90,58</td>
			<td>93</td>
			</tr>
            <tr>
				<td>Aktivlar rentabelligi(ROA)</td>
				<td style={{textAlign:'center'}}>%</td>
				<td>8,2</td>
				<td>8,8</td>
				<td>0,18</td>
			<td>0,11</td>
			<td>14</td>
			<td>13</td>
			</tr>
            <tr>
				<td>Daromad rentabelligi(ROI)</td>
				<td style={{textAlign:'center'}}>%</td>
				<td>0</td>
				<td>0</td>
				<td>0</td>
			<td>0</td>
			<td>0</td>
			<td>0</td>
			</tr>
            <tr>
				<td>Ustav jamg'armasi rentabelligi(ROE)</td>
				<td style={{textAlign:'center'}}>%</td>
				<td>46,5</td>
				<td>54,3</td>
				<td>186</td>
			<td>197</td>
			<td>121</td>
			<td>138</td>
			</tr>
		</tbody>
	</Table>
					</Col>
			
				</Row>

                </Container>
            </div>
        );
    }
}



export default Moliyaviy_korsatkich;
