import React, { Component } from 'react'
import Header1 from './header1'
import Homiy1 from './homiy1'
import About1 from './about1'
import Afzallik1 from './afzallik1'
import HashLoader from "react-spinners/HashLoader";

export default class Dashboard extends Component {
    state={
      loading:true,
    }
    
    componentDidMount(){
        setTimeout(()=>{
            this.setState({
                loading:false
            })
    
        }, 2000)
        
            }

   

    render() {
        return (
            <div>
                           {
              this.state.loading?<div style={{position:"fixed", zIndex:'544322', backgroundColor:'white', display:'flex', justifyContent:'center', alignItems:'center', top:'0px', left:'0px', width:'100%', height:'100%'}} >

              <HashLoader color="blue" loading={this.state.loading} size={100} css={{zIndex:'544322'}}/> 
                          
                      </div>:
<div >
    
        <Header1/>
        <Afzallik1/>
        <About1/>
        <Homiy1/>
    
</div>
                           }

        </div>
        )
    }
}
