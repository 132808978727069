import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import { MDBCard, MDBCardTitle, MDBCardText, MDBCardBody, MDBCardHeader, MDBCardFooter, MDBCardImage, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import styles from '../css/komp_haqida.module.css'
class HisobotlarRu extends React.Component {
   

    render() {
        return (
            <div>
           
              <Container fluid className='mt-5 ' >
               
               <Row>
                 
                 <Col lg={12}>
                 <h3 className='text-center mb-5'>На сайте ведутся технические работы</h3>
                 <div className="blueLine"></div>
                              </Col>
               </Row>
              
              </Container>
            </div>
        );
    }
}



export default HisobotlarRu;
