import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';

import styles from '../css/komp_haqida.module.css'
class Afillangan extends React.Component {
    

    render() {
        return (
            <div>
                <Container fluid className='mt-5 '>
                <Row>
			
					<Col lg={12}>
					<h3 className='text-center mb-5'>Emitetning afillangan shaxslar ro'yxati</h3>
                    <div className="blueLine"></div>
	<Table
      responsive="sm" responsive="md" responsive="lg"
       className={styles.jadval}
       style={{ background: "linearGradient(45deg, #fff, #4f1c72)"}}>
		<thead >
			<tr>
				<th rowSpan="2" style={{textAlign:'center', fontSize:'16px'}}>Afillangan yuridik shaxslarning to'liq nomi<br/>
                yoki afillangan jismoniy shaxslarning F.I.O</th>
				<th rowSpan="2" style={{textAlign:'center', fontSize:'18px'}}>Afillangan shaxs deb<br/> xisoblanishining asosi</th>
				<th colSpan="2" style={{textAlign:'center', fontSize:'18px', lineHeight:"18px"}}>Ustav kapitalidagi ulushi</th>
				
			</tr>
            <tr style={{lineHeight:"11px"}}>

                <th style={{textAlign:'center'}}>soni (dona)</th>
                <th style={{textAlign:'center'}}>ulush (%)</th>
            </tr>
		</thead>
		<tbody >
			
			<tr>
				<td style={{fontSize:'16px'}}>Turdiboyev Alisher Abduvasikovich</td>
				<td style={{textAlign:'center'}}>Kuzatuv kengashi raisi</td>
				<td style={{textAlign:'center'}}>0</td>
				<td style={{textAlign:'center'}}>0</td>
		
			</tr>
			<tr>
				<td style={{fontSize:'16px'}}>Axmetov Istam Hamroyevich</td>
				<td style={{textAlign:'center'}}>Kuzatuv kengashi a'zosi</td>
				<td style={{textAlign:'center'}}> </td>
				<td style={{textAlign:'center'}}> </td>

			</tr>
			<tr>
				<td style={{fontSize:'16px'}}>Hafizov Umidjon Usmonovich</td>
				<td style={{textAlign:'center'}}>Kuzatuv kengashi a'zosi</td>
				<td style={{textAlign:'center'}}>0</td>
				<td style={{textAlign:'center'}}>0</td>

			</tr>
			<tr>
				<td style={{fontSize:'16px'}}>Abdullayev Timur Ruslanovich</td>
				<td style={{textAlign:'center'}}>Kuzatuv kengashi a'zosi</td>
				<td style={{textAlign:'center'}}>0</td>
				<td style={{textAlign:'center'}}>0</td>
				
			</tr>
            <tr>
				<td style={{fontSize:'16px'}}>Sobirov Baxtiyor Mahmudovich</td>
				<td style={{textAlign:'center'}}>Kuzatuv kengashi a'zosi</td>
				<td style={{textAlign:'center'}}>0</td>
				<td style={{textAlign:'center'}}>0</td>
				
			</tr>
            <tr>
				<td style={{fontSize:'16px'}}>Soliyev Elyor Elmurodovich</td>
				<td style={{textAlign:'center'}}>Ijroiya organi a'zosi</td>
				<td style={{textAlign:'center'}}>0</td>
				<td style={{textAlign:'center'}}>0</td>
				
			</tr>
            <tr>
				<td style={{fontSize:'16px'}}>Toshov Nurxon Mirzoyevich</td>
				<td style={{textAlign:'center'}}>Ijroiya organi a'zosi</td>
				<td style={{textAlign:'center'}}>0</td>
				<td style={{textAlign:'center'}}>0</td>
				
			</tr>
            <tr>
				<td style={{fontSize:'16px'}}>Xusanov Farxod Rustamovich</td>
				<td style={{textAlign:'center'}}>Ijroiya organi a'zosi</td>
				<td style={{textAlign:'center'}}>0</td>
				<td style={{textAlign:'center'}}>0</td>

			</tr>

		</tbody>
	</Table>
					</Col>
			
				</Row>

                </Container>
            </div>
        );
    }
}



export default Afillangan;
