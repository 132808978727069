import React, { Component } from 'react'
import style from '../css/xujjatlar.module.css'
import icon1 from '../img/dl.svg'
import icon2 from '../img/pdf.png'
import q from '../pdf/1.pdf'
import q2 from '../pdf/1.pdf'
import q3 from '../pdf/3.pdf'
import q4 from '../pdf/4.pdf'
import q5 from '../pdf/5.pdf'
import q6 from '../pdf/6.pdf'
import q7 from '../pdf/7.pdf'
import q8 from '../pdf/8.pdf'
import q9 from '../pdf/9.pdf'
import q10 from '../pdf/10.pdf'
import q14 from '../pdf/14.pdf'
import q15 from '../pdf/15.pdf'
import q16 from '../pdf/16.pdf'
import q17 from '../pdf/17.pdf'
import q11 from '../pdf/11.pdf'
import q12 from '../pdf/1.pdf'
import q18 from '../pdf/18.xlsx'
import q19 from '../pdf/19.pdf'
import q20 from '../pdf/20.docx'
import q21 from '../pdf/21.pdf'
import q22 from '../pdf/22.pdf'
import q23 from '../pdf/23.pdf'
import q20p from '../pdf/20.pdf'
import q24 from '../pdf/Баланс гадовой Форма -1 2023г.pdf'
import q25 from '../pdf/Баланс годовой Форма-2  2023г.pdf'
import q26 from '../pdf/Баланс форма 1.pdf'
import q27 from '../pdf/Баланс поли год форма 2.pdf'

export default class Xujjatlar extends Component{


render(){
  return(

<div className={style.containerGr}>
  <h5 className={style.aj}>"Neftgaztadqiqot" AJ ichki hujjatlari:</h5>
	<div className="blueLine"></div>
  <h2 className={style.dbr}>Quyidagi hujjatlarni yuklab olishingiz mumkin:</h2>
  <div className={style.hhh}>
  <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Bugalteriya balansi 1-sonli shakli 2024-yil</span></h3>
</div>
			<a download target="_blank" href={q26} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Bugalteriya balansi 2-sonli shakli 2024-yil</span></h3>
</div>
			<a download target="_blank" href={q27} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Bugalteriya balansi 1-sonli shakli 2023-yil</span></h3>
</div>
			<a download target="_blank" href={q24} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Bugalteriya balansi 2-sonli shakli 2023-yil</span></h3>
</div>
			<a download target="_blank" href={q25} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
  <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Moliyaviy natijalar to'g'risidagi hisobot 1-sonli shakli</span></h3>
</div>
			<a download target="_blank" href={q20p} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Moliyaviy natijalar to'g'risidagi hisobot 2-sonli shakli</span></h3>
</div>
			<a download target="_blank" href={q21} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Bugalteriya balansi 1-sonli shakli</span></h3>
</div>
			<a download target="_blank" href={q22} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Bugalteriya balansi 2-sonli shakli</span></h3>
</div>
			<a download target="_blank" href={q23} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
		<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>2022 - yil uchun biznes reja</span></h3>
</div>	
			<a download target="_blank" href={q19} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
		<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Jamiyatning tuzilish sutrukturasi</span></h3>
</div>	
			<a download target="_blank" href={q20} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
		<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Jamiyat ustavi</span></h3>
</div>	
			<a download target="_blank" href={q} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
    <div className={style.grid}>
      
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Aksiyadorlarning umumiy yig'ilishi to'g'risidagi Nizom</span></h3>
			<p><i>(Rus tilida)</i></p></div>

			<a download target="_blank" href={q2} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Kuzatuv kengashi to'g'risidagi Nizom</span></h3>
			<p><i>(Rus tilida)</i></p>
</div>
			<a download target="_blank" href={q3} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Ijro organi to'g'risidagi Nizom</span></h3>
			<p><i>(Rus tilida)</i></p>
      </div>

			<a download target="_blank" href={q4} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Jamiyat taftishchisi to'g'risidagi Nizom</span></h3>
			<p><i>(Rus tilida)</i></p>
</div>
			<a download target="_blank" href={q5} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Ichki audit hizmati to'g'risidagi Nizom</span></h3>
</div>
			<a download target="_blank" href={q6} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Jamiyatning ichki nazorati to'g'risidagi Nizom</span></h3>
			<p><i>(Rus tilida)</i></p>
      </div>
			<a download target="_blank" href={q7} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Jamiyatning axborot siyosati to'g'risidagi Nizomi</span></h3>
			<p><i>(Rus tilida)</i></p>
</div>
			<a download target="_blank" href={q8} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Jamiyatning dvident siyosati to'g'risidagi Nizomi</span></h3>
			<p><i>(Rus tilida)</i></p>
</div>
			<a download target="_blank" href={q9} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>

    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>
        Jamiyatning manfaatlar ixtilofi xolatida faoliyat yuritishi tartib haqidagi Nizomi
        </span>
      </h3>
			<p><i>(Rus tilida)</i></p>
</div>
			<a download target="_blank" href={q10} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>"Neftgaztadqiqot" AJ 2018-yilda rivojlantirish biznes rejasi</span></h3>
</div>
			<a download target="_blank" href={q11} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Emissiya risolasi</span></h3>
</div>
			<a download target="_blank" href={q12} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Jamiyat haqidagi 2020-yilgi ijobiy auditorlik xulosasi</span></h3>
</div>
			<a download target="_blank" href={q14} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Jamiyat haqidagi 2021-yilgi ijobiy auditorlik xulosasi</span></h3>
</div>
			<a download target="_blank" href={q15} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Jamiyat haqidagi 2020-yilgi biznes plani</span></h3>
</div>
			<a download target="_blank" href={q16} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Jamiyat haqidagi 2021-yilgi biznes plani</span></h3>
</div>
			<a download target="_blank" href={q17} className={style.button}>Yuklab olish<img src={icon1}/></a>
		</div>
		
</div>
</div>

  )
}
}
