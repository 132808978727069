import React, { Component } from 'react'
import styles from './css/header.module.css'
import { Carousel } from 'antd';


export default class Header1Ru extends Component {
    render() {
        const contentStyle = {
            height: '160px',
            color: '#fff',
            lineHeight: '160px',
            textAlign: 'center',
            background: '#364d79',
          }
        return (
            <div>
                <Carousel autoplay
                >
                    <div className={styles.sliderDiv}>
                    
                    <div className={styles.sliderInsideDiv}>
                       <h1 style={{fontSize:'29px'}}>"Нефтегазисследование" AО</h1>
                       <h2>c 1959 года</h2>
                       <p> Предприятие существует с начала ввода в эксплуатацию первых месторождений нефти и газа, расположенных в Бухарской области.</p>
                    </div>
                    </div>
                    <div className={styles.sliderDiv,styles.slider2Div}>
                    <div className={styles.sliderInsideDiv}>
                       <h1 style={{fontSize:'29px'}}>"Нефтегазисследование" AО</h1>
                       <h2>В 1964 году</h2>
                       <p>Предприятие под наименованием «Центральная научно-исследовательская лаборатория» становится структурной единицей ПО «Бухаранефтегаз»</p>
                    </div>
                    </div>
                    <div className={styles.sliderDiv,styles.slider3Div}>
                    <div className={styles.sliderInsideDiv}>
                       <h1 style={{fontSize:'29px'}}>"Нефтегазисследование" AО</h1>
                       <h2>В 1995 году</h2>
                       <p>Предприятие преобразовано в Открытое акционерное общество «Нефтегазисследование».</p>
                    </div>
                    </div>
                    <div className={styles.sliderDiv,styles.slider4Div}>
                    <div className={styles.sliderInsideDiv}>
                       <h1 style={{fontSize:'29px'}}>"Нефтегазисследование" AО</h1>
                       
                       <p>Деятельность АО  «Нефтегазисследование» осуществляется на месторождениях нефтегазодобывающих предприятий Национальной холдинговой компании  «Узбекнефтегаз».</p>
                       
                    </div>
                    </div>
                </Carousel>
            </div>
        )
    }
}
