import { Container, Row, Col, Card } from 'react-bootstrap'
import React, { Component } from 'react'
import style1 from '../css/App.module.css'
import { CloudDownloadOutlined } from '@ant-design/icons'
import { getAccountant } from '../host/Config'
import { url } from '../host/Host'
import HashLoader from "react-spinners/HashLoader";

export default class Bugalter extends Component {
  state={
    accountant:[],
    loading:true
  }
    download=(link)=>{
     window.location.href=link
  }
  
  componentDidMount(){
    getAccountant().then(res=>{
     this.setState({
       accountant: res.data,
       loading:false
  
     })
    }).catch(err=>{
      console.log(err)
    })
  }
      render() {
          return (
              <div style={{paddingTop:'40px'}}>
              {
                this.state.loading?<div  style={{position:"fixed", zIndex:'344322', backgroundColor:'white', display:'flex', justifyContent:'center', alignItems:'center', top:'0px', left:'0px', width:'100%', height:'100%'}} >
  
                <HashLoader color="blue" loading={this.state.loading} size={100} css={{zIndex:'432323'}}/> 
                            
                        </div>:
                <Container>
                  <Row>
                  {
                    this.state.accountant && Array.isArray(this.state.accountant) && this.state.accountant!=[]?this.state.accountant.map(item=>{
return(<Col lg={4} md={6} sm={12} style={{padding:'10px 20px'}}>
<Card className={style1.bugCard} >
  <Card.Body>
    {/* <Card.Title>Card Title</Card.Title> */}
    {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
    <Card.Text style={{fontWeight:'bold'}}>
     Финансовые отчеты общества за {item.textRu}:
    </Card.Text>
    <Card.Link  onClick={()=>{this.download(`${item.accountantAttachHashCode}`)}} target="_blank" className={style1.bugLink}>Бухгалтерский баланс <CloudDownloadOutlined style={{position:'absolute', right:'10px', top:'10px'}} /></Card.Link><br/>
    <Card.Link onClick={()=>{this.download(`${item.reportAttachHashCode}`)}} className={style1.bugLink}>Финанасовых результатах <CloudDownloadOutlined style={{position:'absolute', right:'10px', top:'10px'}} /></Card.Link>
  </Card.Body>
</Card>
</Col>)
                    }):<div className="nodata">
                    <h1>Нет данных</h1>
              </div>
                  }
                  
                       



                </Row>
                  </Container>  
                  }            </div>
        )
    }
}
