import React, { Component } from 'react'
import styles from '../css/Bolimlar.module.css'
import bolimlar1 from '../img/bolimlar1.jpg'
import {Container,Row,Col} from 'react-bootstrap'
import {CheckOutlined} from '@ant-design/icons'
import bolim2 from '../img/bolim2.jpg'
import bolim3 from '../img/bolim3.jpg'
import bolim4 from '../img/bolim4.jpg'
import bolim6 from '../img/bolim6.jpg'
import bolim5 from '../img/bolim5.jpg'
import { Link } from 'react-router-dom'
export default class BolimlarRu extends Component {
    render() {
        return (
            <div>
                <div className={styles.bolimlar}>
                    <Container fluid className={styles.firstBolim}>
                        <Row>
                        <Col lg={12}>
                                <Row style={{display:'flex', justifyContent:'center'}}>
                                <Col lg={4} md={6} sm={12}>
                                        <Link to="/vakantsiya/ru" className={styles.butt}>
                                        Вакансия
                                        </Link>
                                    </Col>
                                    <Col lg={4} md={6} sm={12}>
                                        <Link to="/konkurs/ru" className={styles.butt}>
                                              Тендеры
                                        </Link>
                                    </Col>
                                    <Col lg={4} md={6} sm={12}>
                                        <Link to="/ru" className={styles.butt}>
                                              Деловая активность и цели
                                        </Link>
                                    </Col>
                                    
                                    <Col lg={4} md={6} sm={12}>
                                        <Link to="/moliya/ru" className={styles.butt}>
                                              Финансовые показатели
                                        </Link>
                                    </Col>
                                     
                                    <Col lg={4} md={6} sm={12}>
                                        <Link to="/aloqalar/ru" className={styles.butt}>
                                              Подавать заявление
                                        </Link>
                                    </Col>
                                    
                                    <Col lg={4} md={6} sm={12}>
                                        <Link to="/hisobotlar/ru" className={styles.butt}>
                                              Oтчеты
                                        </Link>
                                    </Col>
                                    <Col lg={4} md={6} sm={12}>
                                        <Link to="/xujjatlar/ru" className={styles.butt}>
                                              Внутренние документы
                                        </Link>
                                    </Col>
                                    <Col lg={4} md={6} sm={12}>
                                        <Link to="/afillik/ru" className={styles.butt}>
                                        Аффилированные лица
                                        </Link>
                                    </Col>
                                    <Col lg={4} md={6} sm={12}>
                                        <Link to="/strategiya/ru" className={styles.butt}>
                                        Стратегия развития
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>
                           
                            <Col lg={12}>
                            <h1>Для осуществления деятельности в АО организовано 6 отделов</h1>
                            </Col>
                            <Col lg={6} md={12} sm={12}>
                                <img src={bolimlar1}/>
                            </Col>
                            <Col lg={6} md={12} sm={12} className={styles.headingBolim}>
                                <h2>Отделом нефтегазопромысловых исследований выполняются работы на месторождениях нефти и газа с целью определения:</h2>
                            </Col>
                            <Col  xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar1}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> текущих продуктивных характеристик эксплуатационных скважин</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar2}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> газоконденсатной характеристики добываемого газа</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar3}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> текущего водного фактора эксплуатационных скважин;</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar4}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> эффективности работы СП по улавливанию газоконденсата;</span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className={styles.secondBolim}>
                        <Row>
                            <Col lg={6} md={12} sm={12} className={styles.headingSecondBolim}>
                                <h1>Oтделом исследований свойств и составов газов, газоконденсатов, нефтей и пластовых вод выполняются:</h1>
                            </Col>
                            <Col lg={6} md={12} sm={12} className={styles.imageSecondBolim}>
                                <img src={bolim2}/>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar5}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> физико-химические анализы добываемой продукции</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar6}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> гидрохимический контроль за обводненностью нефтяных и газовых скважин</span>
                                </div>
                            </Col>
                            
                        </Row>
                    </Container>
                    <Container fluid className={styles.thirdBolim}>
                        <Row>
                        
                            <Col lg={6} md={12} sm={12} className={styles.imageSecondBolim}>
                                <img src={bolim3}/>
                            </Col>
                            <Col lg={6} md={12} sm={12} className={styles.headingSecondBolim}>
                                <h1>Отделом подготовки, переработки газа и охраны окружающей среды проводятся:</h1>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar7}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> исследования показателей качества подготовки газа к транспорту</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar8}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> газоконденсатные исследования на УКПГ этих предприятий</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar9}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> контроль качества газа, транспортируемого с предприятий АК »Узнефтегаздобыча»</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar10}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> определение количества и состава выбросов вредных веществ в атмосферу и водные объекты на предприятиях отрасли</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar11}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> проведение лабораторных и пилотных испытаний цеолитов используемых для осушки и очистки природного газа</span>
                                </div>
                            </Col>
                            
                        </Row>
                    </Container>
                    <Container fluid className={styles.firstBolim}>
                        <Row>
                            <Col lg={6} md={12} sm={12}>
                                <img src={bolim4}/>
                            </Col>
                            <Col lg={6} md={12} sm={12} className={styles.headingBolim}>
                                <h2>Отделом электрохимзащиты выполняются работы с целью определения</h2>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar1}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> защищенности линейной части трубопроводов и коммуникаций промышленных</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar2}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> участков с повреждениями изоляционного покрытия подземных трубопроводов</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar3}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> коррозионно-опасных участков грунтов, в которых уложены трубопроводы</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar4}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> оптимальных токов защиты.</span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className={styles.secondBolim}>
                        <Row>
                            <Col lg={6} md={12} sm={12} className={styles.headingSecondBolim}>
                                <h1>Отделом ингибиторной защиты нефтегазопромыслового оборудования и трубопроводов проводится исследовательские работы по определению:</h1>
                            </Col>
                            <Col lg={6} md={12} sm={12} className={styles.imageSecondBolim}>
                                <img src={bolim5}/>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar5}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> степени защиты при ингибировании</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar6}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> степени наводораживания</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar12}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> эффективности применяемых ингибиторов коррозии</span>
                                </div>
                            </Col>
                            
                        </Row>
                    </Container>
                    <Container fluid className={styles.secondBolim}>
                        <Row>
                            <Col lg={6} md={12} sm={12} className={styles.imageSecondBolim}>
                                <img src={bolim6}/>
                            </Col>
                            <Col lg={6} md={12} sm={12} className={styles.headingSecondBolim}>
                                <h1>Отделом нормирования материально-технических и топливно-энергетических ресурсов</h1>
                            </Col>
                            <Col lg={12}>
                                <div className={styles.cardsBolimlar13}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> разрабатываются нормы расхода МТР для предприятий по добыче нефти и газа, переработке газа, капитального ремонта скважин, а также нормы расхода газа, газоконденсата на собственные нужды и потери газодобывающих предприятий. Номенклатура МТР имеет более 20 наименований. Расчеты отдела используются при планировании поставок МТР, составлении балансов газа и конденсата по АК "Узнефтегаздобыча".</span>
                                </div>
                            </Col>
                            
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}
