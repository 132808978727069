import React, { Component } from 'react'
import styles from './css/about.module.css'
import {Container,Row,Col} from 'react-bootstrap'
export default class About1 extends Component {
    render() {
        return (
           <div>
           <Container fluid className={styles.aboutOuter}>
             <Row>
                 <Col lg={12}>
                 <div className={styles.aboutIndex}>
                    <h1>Biz haqimizda</h1>
                    <p>Korxona Buxoro viloyati hududida ilk neft va gaz konlarini ishga tushirish asnosida (1959-yil) o'z faoliyatini boshlagan. Korxonaning tashkil etilishidan asosiy maqsad-neft va gazni qazib olish, ularni naqliyotga tayyorlash, hamda tashish (uzatish) texnologiyalarini takomillashtirishga qaratilgan tadqiqot ishlarini amalga oshirishdan iborat bo'lgan. 1964-yildan korxona "Markaziy ilmiy - tadqiqot laboratoriyasi" nomi bilan "Buxoroneftgaz" ishlab chiqarish brilashmasining tarkibiga kiritildi.</p>
                    <p>1995-yilda korxonaning mulkchilik shakli o'zgartirilib, "Neftgaztadqiqot" ochiq aksiyadorlik jamiyatiga aylantirildi.</p>
                </div>
                 </Col>
             </Row>
           </Container>
           </div>
        )
    }
}
