import React, { Component } from 'react'
import styles from './css/afzallik.module.css'
import {Container,Row,Col} from 'react-bootstrap'
import {DeploymentUnitOutlined,EnvironmentOutlined,DollarCircleOutlined,UserOutlined,CheckOutlined,FileDoneOutlined,BranchesOutlined ,ConsoleSqlOutlined   } from '@ant-design/icons'
export default class Afzallik1 extends Component {
    render() {
        return (
            <div>
                <Container fluid className={styles.afzalliklar}>
                <Row>
                    <Col lg={42}>
                    <h1>Bizning afzalliklarimiz</h1>
                    </Col>
                    <Col  xl={4} lg={4} md={6} sm={12}>
                        <div className={styles.cardAfzalliklar}>
                            <div>
                            <DeploymentUnitOutlined style={{fontSize:'40px',color:'white'}}/>
                            </div>
                            <div>
    
                                <h2>"Neftgaztadqiqot" AJ negizida xalqaro akkreditatsiyadan o'tgan tadqiqot laboratoriyasi mavjud.</h2>
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}  lg={4} md={6} sm={12}>
                        <div className={styles.cardAfzalliklar}>
                            <div>
                            <EnvironmentOutlined style={{fontSize:'40px',color:'white'}}/>
                            </div>
                            <div>
    
                                <h2>Korxonaning geografik joylashishini hisobga olgan holda, dala tadqiqot guruhlarining yuqori harakatchanligi (guruh buyurtmaning ob'ektiga bir ish kuni ichida etib borishi mumkin).</h2>
                            </div>
                        </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12}>
                        <div className={styles.cardAfzalliklar}>
                            <div>
                            <DollarCircleOutlined style={{fontSize:'40px',color:'white'}}/>                        
                            </div>
                            <div>
    
                                <h2>Tadqiqot va ishlanmalar uchun optimal narxlar.</h2>
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}  lg={4} md={6} sm={12}>
                        <div className={styles.cardAfzalliklar}>
                            <div>
                            <UserOutlined style={{fontSize:'40px',color:'white'}}/>
                            </div>
                            <div>
    
                                <h2>Faoliyat yo'nalishlari bo'yicha yuqori malakali mutaxassislarning mavjudligi.</h2>
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}  lg={4} md={6} sm={12}>
                        <div className={styles.cardAfzalliklar}>
                            <div>
                            <FileDoneOutlined style={{fontSize:'40px',color:'white'}}/>
                            
                            </div>
                            <div>
    
                                <h2>Xalqaro sertifikatlangan yuqori sifatli tadqiqotlar.</h2>
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}  lg={4} md={6} sm={12}>
                        <div className={styles.cardAfzalliklar}>
                            <div>
                            <CheckOutlined style={{fontSize:'40px',color:'white'}}/>
                            
                            </div>
                            <div>
    
                                <h2>O'zbekiston Respublikasiga olib kiriladigan korroziya ingibitorlari va seolitlarini sinovdan o'tkazish kabi tor profillaktik tadqiqotlar o'tkazish imkoniyati.</h2>
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}  lg={4} md={6} sm={12}>
                        <div className={styles.cardAfzalliklar}>
                            <div>
                            <BranchesOutlined style={{fontSize:'40px',color:'white'}}/>
                            
                            </div>
                            <div>
    
                                <h2>"O'zbekneftgaz" AJ tarkibiy tuzilmalari va xorijiy korxonalar bilan ishlashning katta tajribasi.</h2>
                            </div>
                        </div>
                    </Col>
                    <Col xl={4}  lg={4} md={6} sm={12}>
                        <div className={styles.cardAfzalliklar}>
                            <div>
                            <ConsoleSqlOutlined  style={{fontSize:'40px',color:'white'}}/>
                            
                            </div>
                            <div>
    
                                <h2>Ilmiy-tadqiqot ishlarini olib borishda ulkan ma'lumotlar bazasi va tajribaning mavjudligi....</h2>
                            </div>
                        </div>
                    </Col>
                </Row>
                </Container>
            </div>
        )
    }
}
