import { Menu, Switch } from 'antd'
import React, { Component } from 'react'
import { MailOutlined,  HomeOutlined, CreditCardOutlined, CopyOutlined, BankOutlined, UserOutlined, DollarOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import style from '../css/Navbar.module.css'
const { SubMenu } = Menu;

export default class Navbar extends Component {
    state = {
        current: 'mail',
        theme: 'white',
        textTheme:'black',
        currentS: '1',
        hover:'blue'
      };
 
      
  changeTheme = value => {
    this.setState({
        theme: value ? 'white' : '#02325f',
        textTheme: value ? 'black' : 'white',
        hover: value ? 'blue' : 'gold',
    });
    localStorage.removeItem('theme')
    localStorage.removeItem('textTheme')
    localStorage.removeItem('hover')

    localStorage.setItem('theme', value ? 'white' : '#02325f')
    localStorage.setItem('textTheme', value ? 'black' : 'white')
    localStorage.setItem('hover', value ? 'blue' : 'gold')
    
    
    
  };
      handleClick = e => {
        console.log('click ', e);
        this.setState({ current: e.key,
            currentS: e.key, });
              };
    
    render() {

        return (
           <div style={{position:'sticky', top:'-20px', left:"0px", zIndex:'45454', width:'100%'}}>
      {
        window.location.href.split('/')[window.location.href.split('/').length-1]==='uz' || window.location.href.split('/')[window.location.href.split('/').length-1]===''?
        <div className="nav" style={{position:'sticky', top:'-20px', left:"0px", zIndex:'223', width:'100%'}}>
        <Menu className="navbarK" style={{padding:'10px 10px 20px 10px', zIndex:'123', position:'relative', backgroundColor:this.state.theme, marginTop:'15px', color:this.state.textTheme, width:'100%'}} onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
        <SubMenu style={{width:'190px', borderRight:'1px solid #dee2e6', marginLeft:'30px'}} key="SubMenu"  icon={<HomeOutlined style={{position:'relative', top:'-2px'}}/>} title="Kompaniya haqida">
           <Menu.ItemGroup  style={{backgroundColor:this.state.theme, zIndex:'123', color:this.state.textTheme}} >
          
           <Menu.Item key="setting:11"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/uz">Korxona faoliyati va maqsadlari haqida</NavLink></Menu.Item>
             <Menu.Item key="setting:31"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/boshqaruv/uz">Boshqaruv</NavLink></Menu.Item>
             <Menu.Item key="setting:41"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/tuzilmalar/uz">Tashkiliy tuzilma</NavLink></Menu.Item>
             <Menu.Item key="setting:51"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/strategiya/uz">Rivojlanish strategiyasi</NavLink></Menu.Item>
             <Menu.Item key="setting:61"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/moliya/uz">Moliyaviy ko'rsatkichlar</NavLink></Menu.Item>
             <Menu.Item key="setting:71"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/tarix/uz">Tashkilot tarixi</NavLink></Menu.Item>
             <Menu.Item key="setting:81"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/vakantsiya/uz">Vakantsiya</NavLink></Menu.Item>
             <Menu.Item key="setting:91"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/aloqalar/uz">
          Aloqalar
          </NavLink></Menu.Item>
             
        
           </Menu.ItemGroup>
          
         </SubMenu>
         <SubMenu style={{width:'190px', borderRight:'1px solid #dee2e6'}} key="SubMenu1"  icon={<CreditCardOutlined style={{position:'relative', top:'-2px'}}/>} title="Aksiyadorlarga">
           <Menu.ItemGroup  style={{backgroundColor:this.state.theme, zIndex:'123', color:this.state.textTheme}} >
             <Menu.Item key="setting:1"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/ijro_organlari/uz">Ijro organi a'zolari</NavLink></Menu.Item>
             <Menu.Item key="setting:2"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/afillik/uz">Affillangan shaxslar</NavLink></Menu.Item>
             <Menu.Item key="setting:3"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/aksiya/uz">Dividentlar</NavLink></Menu.Item>
             <Menu.Item key="setting:4"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/kengash/uz">Kuzatuv kengashi a'zolari</NavLink></Menu.Item>
             <Menu.Item key="setting:5"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/xujjatlar/uz">Ichki hujjatlar</NavLink></Menu.Item>
             <Menu.Item key="setting:6"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/faktlar/uz">Muhim faktlar</NavLink></Menu.Item>
             <Menu.Item key="setting:7"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/hisobotlar/uz">Hisobotlar</NavLink></Menu.Item>
           </Menu.ItemGroup>
          
         </SubMenu>
         <Menu.Item style={{width:'190px' , borderRight:'1px solid #dee2e6'}}  key="mail4" icon={<BankOutlined style={{position:'relative', top:'-2px'}}/>}>
        <NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}}  to="/bo'lim/uz">Bo'limlar
        </NavLink>
        </Menu.Item>
        
         <Menu.Item style={{width:'190px' , borderRight:'1px solid #dee2e6'}}  key="mail1" icon={<CopyOutlined style={{position:'relative', top:'-2px'}}/>}>
 <NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/bugalteriya/uz">
          Ochiq ma'lumotlar
          </NavLink>        
         </Menu.Item>
         <Menu.Item style={{width:'190px' , borderRight:'1px solid #dee2e6'}}  key="mail2" icon={<DollarOutlined style={{position:'relative', top:'-2px'}}/>}>
         <NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/konkurs/uz">
        Tenderlar 
        </NavLink>
        </Menu.Item>
         <Menu.Item style={{width:'190px' , borderRight:'1px solid #dee2e6'}}  key="mail3" icon={<UserOutlined style={{position:'relative', top:'-2px'}}/>}>
         <NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/elon/uz">
          E'lonlar
          </NavLink>
         </Menu.Item>
        
        
       </Menu>

      <div class="page">
  <span class="page__name"></span>
  <span class="page__hint"></span>
</div>
<div class="menu">
  <nav class="menu__nav">
    <ul class="menu__list r-list">
      <li class="menu__group">
        <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/uz">Korxona faoliyati va maqsadlari haqida</NavLink>
      </li>
      <li class="menu__group">
        <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/bo'lim/uz">Bo'limlar</NavLink>
      </li>
      <li class="menu__group">
        <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/boshqaruv/uz">Boshqaruv</NavLink>
      </li>
      <li class="menu__group">
        <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/tuzilmalar/uz">Tashkiliy tuzilma</NavLink>
      </li>
      <li class="menu__group">
        <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/strategiya/uz">Rivojlanish strategiyasi</NavLink>
      </li>
      <li class="menu__group">
        <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/moliya/uz">Moliyaviy ko'rsatkichlar</NavLink>
      </li>
      <li class="menu__group">
        <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/tarix/uz">Tashkilot tarixi</NavLink>
      </li>
      <li class="menu__group">
       <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/ijro_organlari/uz">Ijro organi a'zolari</NavLink>
      </li>
      <li class="menu__group">
       <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/afillik/uz">Affillangan shaxslar</NavLink>
      </li>
      <li class="menu__group">
       <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/aksiya/uz">Dividentlar</NavLink>
      </li>
      <li class="menu__group">
       <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/kengash/uz">Kuzatuv kengashi a'zolari</NavLink>
      </li>
      <li class="menu__group">
       <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/hujjatlar/uz">Ichki hujjatlar</NavLink>
      </li>
      <li class="menu__group">
       <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/faktlar/uz">Muhim faktlar</NavLink>
      </li>
       <li class="menu__group">
       <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/bugalteriya/uz">
          Ochiq ma'lumotlar
          </NavLink>        
      </li>
      <li class="menu__group">
       <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/konkurs/uz">
        Tenderlar 
        </NavLink>
      </li>
      <li class="menu__group">
       <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/elon/uz">
          E'lonlar
          </NavLink>
      </li>
      <li class="menu__group">
       <NavLink activeStyle={{color:this.state.hover}} style={{fontSize:'14px', color:'white'}} to="/aloqalar/uz">
          Aloqalar
          </NavLink>
      </li>
      
    </ul>
  </nav>
  <button class="menu__toggle r-button" type="button">
    <span class="menu__hamburger m-hamburger">
      <span class="m-hamburger__label">
        <span class="menu__screen-reader screen-reader">Open menu</span>
      </span>
    </span>
  </button>
</div>  
</div>
       
       :
       
      //  Ruschasi
<div className="nav" style={{position:'sticky', top:'-20px', left:"0px", zIndex:'232323', width:'100%'}}>
        
       <Menu className="navbarK" style={{padding:'10px 10px 20px 10px', zIndex:'123', position:'relative', backgroundColor:this.state.theme, marginTop:'15px', color:this.state.textTheme, width:'100%'}} onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
       <SubMenu style={{width:'190px', borderRight:'1px solid #dee2e6', marginLeft:'30px'}} key="SubMenu"  icon={<HomeOutlined style={{position:'relative', top:'-2px'}}/>} title="О компании">
          <Menu.ItemGroup  style={{backgroundColor:this.state.theme, zIndex:'123', color:this.state.textTheme}} >
            <Menu.Item key="setting:11"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/ru">О деятельности и целях предприятия</NavLink></Menu.Item>
            
            <Menu.Item key="setting:31"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/boshqaruv/ru">Управление</NavLink></Menu.Item>
            <Menu.Item key="setting:41"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/tuzilmalar/ru">Организационная структура</NavLink></Menu.Item>
            <Menu.Item key="setting:51"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/strategiya/ru">Стратегия развития</NavLink></Menu.Item>
            <Menu.Item key="setting:61"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/moliya/ru">Финансовые показатели</NavLink></Menu.Item>
            <Menu.Item key="setting:71"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/tarix/ru">История организации</NavLink></Menu.Item>
            <Menu.Item key="setting:81"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/vakantsiya/ru">Вакансия</NavLink></Menu.Item>
            <Menu.Item key="setting:91"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/aloqalar/ru">
        Контакты
        </NavLink></Menu.Item>
           
          </Menu.ItemGroup>
         
        </SubMenu>
        <SubMenu style={{width:'190px', borderRight:'1px solid #dee2e6'}} key="SubMenu1"  icon={<CreditCardOutlined style={{position:'relative', top:'-2px'}}/>} title="Акционерам">
          <Menu.ItemGroup  style={{backgroundColor:this.state.theme, zIndex:'123', color:this.state.textTheme}} >
            <Menu.Item key="setting:1"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/ijro_organlari/ru">Члены исполнительного органа</NavLink></Menu.Item>
            <Menu.Item key="setting:2"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/afillik/ru">Аффилированные лица</NavLink></Menu.Item>
            <Menu.Item key="setting:3"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/aksiya/ru">Дивиденды</NavLink></Menu.Item>
            <Menu.Item key="setting:4"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/kengash/ru">Члены наблюдательного совета</NavLink></Menu.Item>
            <Menu.Item key="setting:5"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/xujjatlar/ru">Внутренние документы</NavLink></Menu.Item>
            <Menu.Item key="setting:6"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/faktlar/ru">Существенные факты</NavLink></Menu.Item>
            <Menu.Item key="setting:6"><NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/hisobotlar/ru">Отчеты</NavLink></Menu.Item>
          </Menu.ItemGroup>
         
        </SubMenu>
        <Menu.Item style={{width:'190px' , borderRight:'1px solid #dee2e6'}}  key="mail4" icon={<BankOutlined style={{position:'relative', top:'-2px'}}/>}>
        <NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}}  to="/bo'lim/ru">Разделы
        </NavLink>
        </Menu.Item>
       
        <Menu.Item style={{width:'190px' , borderRight:'1px solid #dee2e6'}}  key="mail1" icon={<CopyOutlined style={{position:'relative', top:'-2px'}}/>}>
<NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/bugalteriya/ru">
Открытые данные
         </NavLink>        
        </Menu.Item>
        <Menu.Item style={{width:'190px' , borderRight:'1px solid #dee2e6'}}  key="mail2" icon={<DollarOutlined style={{position:'relative', top:'-2px'}}/>}>
        <NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/konkurs/ru">
        Тендеры 
        </NavLink>
        </Menu.Item>
        <Menu.Item style={{width:'190px' , borderRight:'1px solid #dee2e6'}}  key="mail3" icon={<UserOutlined style={{position:'relative', top:'-2px'}}/>}>
        <NavLink activeStyle={{color:this.state.hover}} style={{ color:this.state.textTheme}} to="/elon/ru">
        Объявления
        </NavLink>
        </Menu.Item>
        
       
      
      </Menu>        

<div class="page">
<span class="page__name"></span>
<span class="page__hint"></span>
</div>
<div class="menu">
<nav class="menu__nav">
  <ul class="menu__list r-list">
    <li class="menu__group">
      <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/ru">О деятельности и целях предприятия</NavLink>
    </li>
    <li class="menu__group">
      <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/bo'lim/ru">Разделы</NavLink>
    </li>
    <li class="menu__group">
      <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/boshqaruv/ru">Управление</NavLink>
    </li>
    <li class="menu__group">
      <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/tuzilmalar/ru">Организационная структура</NavLink>
    </li>
    <li class="menu__group">
      <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/strategiya/ru">Стратегия развития</NavLink>
    </li>
    <li class="menu__group">
      <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/moliya/ru">Финансовые показатели</NavLink>
    </li>
    <li class="menu__group">
      <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/tarix/ru">История организации</NavLink>
    </li>
    <li class="menu__group">
     <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/ijro_organlari/ru">Члены исполнительного органа</NavLink>
    </li>
    <li class="menu__group">
     <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/afillik/ru">Аффилированные лица</NavLink>
    </li>
    <li class="menu__group">
     <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/aksiya/ru">Дивиденды</NavLink>
    </li>
    <li class="menu__group">
     <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/kengash/ru">Члены наблюдательного совета</NavLink>
    </li>
    <li class="menu__group">
     <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/xujjatlar/ru">Внутренние документы</NavLink>
    </li>
    <li class="menu__group">
     <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/faktlar/ru">Существенные факты</NavLink>
    </li>
     <li class="menu__group">
     <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/bugalteriya/ru">
Открытые данные
         </NavLink>        
    </li>
    <li class="menu__group">
     <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/konkurs/ru">
        Тендеры 
        </NavLink>
    </li>
    <li class="menu__group">
     <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/elon/ru">
        Объявления
        </NavLink>
    </li>
    <li class="menu__group">
     <NavLink activeStyle={{color:this.state.hover}}  style={{fontSize:'14px', color:'white'}} to="/aloqalar/ru">
        Контакты
        </NavLink>
    </li>
    
  </ul>
</nav>
<button class="menu__toggle r-button" type="button">
  <span class="menu__hamburger m-hamburger">
    <span class="m-hamburger__label">
      <span class="menu__screen-reader screen-reader">Open menu</span>
    </span>
  </span>
</button>
</div></div>  
}
               </div>
        )
    }
}
