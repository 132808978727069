import React, { Component } from 'react'
import {Card, Button} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import style from '../css/Ijro.module.css'
import image from '../img/pl.png'
export default class Kengash extends Component{


render(){
  return(
	<div className={style.tana}>
        <h4 className={style.sarl}>Kuzatuv kengashi a'zolari ro'yxati</h4>
	<div className="blueLine"></div>
          <p align="center">(2020-yil 1-iyul xolatida)</p>
<Card className={style.qart}>
  <Card.Header as="h5" className={style.ism}>Turdibayev Alisher Abduvasikovich</Card.Header>
  <Card.Body className={style.cBody}>
      <div className={style.rasm}>
        <img src={image}/>
        <h6>Turdibayev Alisher Abduvasikovich</h6>
      </div>
      <div className={style.tavsif}><Card.Title><b>«Xududgazta'minot» AJ boshqaruv raisi o'rinbosari</b><br/>
      </Card.Title>
    <Card.Text>
        <br/>
        <p className={style.ulush,style.ulush1}><b>
            Ustav jamg'armasidagi ulushi:</b>  0 dona</p>
        <p className={style.ulush}><b>
            Ustav fondidagi ulushi:</b>  0 %</p>
    </Card.Text><b></b> 
    </div>
  </Card.Body>
</Card>
<Card className={style.qart}>
  <Card.Header as="h5" className={style.ism}>Axmetov Istam Xamroyevich</Card.Header>
  <Card.Body className={style.cBody}>
      <div className={style.rasm}>
        <img src={image}/>
        <h6>Axmetov Istam Xamroyevich</h6>
      </div>
      <div className={style.tavsif}><Card.Title><b>"IDEAL DIZAYN" oilaviy korxona tasischisi</b><br/>
      </Card.Title>
    <Card.Text>
        <br/>
        <p className={style.ulush} className={style.ulush1}><b>
            Ustav jamg'armasidagi ulushi:</b>  0 dona</p>
        <p className={style.ulush}><b>
            Ustav fondidagi ulushi:</b>  0 %</p>
    </Card.Text><b></b> 
    </div>
  </Card.Body>
</Card>
<Card className={style.qart}>
  <Card.Header as="h5" className={style.ism}>Hafizov Umidjon Usmonovich</Card.Header>
  <Card.Body className={style.cBody}>
      <div className={style.rasm}>
        <img src={image}/>
        <h6>Hafizov Umidjon Usmonovich</h6>
      </div>
      <div className={style.tavsif}><Card.Title><b>"O'ZNEFTGAZQUDUQTA'MIRLASH" aksiyadorlik jamiyati Moliyaviy va muvofiqlikni nazorat qilish bo'lim boshlig'i</b><br/>
      </Card.Title>
    <Card.Text>
        <p className={style.ulush} className={style.ulush1}><b>
            Ustav jamg'armasidagi ulushi:</b>  0 dona</p>
        <p className={style.ulush}><b>
            Ustav fondidagi ulushi:</b>  0 %</p>
    </Card.Text><b></b> 
    </div>
  </Card.Body>
</Card>
<Card className={style.qart}>
  <Card.Header as="h5" className={style.ism}>Abdullayev Timur Rustamovich</Card.Header>
  <Card.Body className={style.cBody}>
      <div className={style.rasm}>
        <img src={image}/>
        <h6>Abdullayev Timur Rustamovich</h6>
      </div>
      <div className={style.tavsif}><Card.Title><b>«Enter Engineering PTE Ltd» korxonasida ma'muriy direktor</b><br/>
      </Card.Title>
    <Card.Text>
        <br/>
        <p className={style.ulush} className={style.ulush1}><b>
            Ustav jamg'armasidagi ulushi:</b>  0 dona</p>
        <p className={style.ulush}><b>
            Ustav fondidagi ulushi:</b>  0 %</p>
    </Card.Text><b></b> 
    </div>
  </Card.Body>
</Card>
<Card className={style.qart}>
  <Card.Header as="h5" className={style.ism}>Sobirov Baxtiyor Maxmudovich</Card.Header>
  <Card.Body className={style.cBody}>
      <div className={style.rasm}>
        <img src={image}/>
        <h6>Sobirov Baxtiyor Maxmudovich</h6>
      </div>
      <div className={style.tavsif}><Card.Title><b>Buxoro viloyati hokimligi 
        Rasmiy mexmonlar qabullar uyi direktori</b><br/>
      </Card.Title>
    <Card.Text>
        <br/>
        <p className={style.ulush} className={style.ulush1}><b>
            Ustav jamg'armasidagi ulushi:</b>  0 dona</p>
        <p className={style.ulush}><b>
            Ustav fondidagi ulushi:</b>  0 %</p>
    </Card.Text><b></b> 
    </div>
  </Card.Body>
</Card>
	</div>
  )
}
}