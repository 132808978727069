import Header from './pages/Header'
import React, { Component } from 'react'
import './App.css'
import Navbar from './pages/Navbar'
import Footer from './pages/Footer'
import { Row, Col } from 'react-bootstrap'
import News from './pages/News'
import Bugalter from './pages/Bugalter'
import BugalterRu from './pages/BugalterRu'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Ijro from './pages/Ijro'
import IjroRu from './pages/IjroRu'
import Aksiya from './pages/Aksiya'
import AksiyaRu from './pages/AksiyaRu'
import Dashboard from './dashboard/dashboard/Dashboard'
import DashboardRu from './dashboard/dashboard/DashboardRu'
import Strategiya from './pages/Strategiya'
import StrategiyaRu from './pages/StrategiyaRu'
import Elon from './pages/Elon'
import Aloqa from './pages/Aloqa'
import AloqaRu from './pages/AloqaRu'
import Fakt from './pages/Fakt'
import FaktRu from './pages/FaktRu'
import Kengash from './pages/Kengash'
import KengashRu from './pages/KengashRu'
import Xujjatlar from './pages/Xujjatlar'
import XujjatlarRu from './pages/XujjatlarRu'
import Konkurslar from './pages/Konkurslar'
import Bolimlar from './pages/Bolimlar'
import KonkurslarRu from './pages/KonkurslarRu'
import BolimlarRu from './pages/BolimlarRu'
import Tashkiliy_tuzilma from './pages/Tashkiliy_tuzilma'
import Tashkiliy_tuzilmaRu from './pages/Tashkiliy_tuzilmaRu'
import Boshqaruv from './pages/Boshqaruv'
import Moliyaviy_korsatkich from './pages/Moliyaviy_korsatkich'
import BoshqaruvRu from './pages/BoshqaruvRu'
import Moliyaviy_korsatkichRu from './pages/Moliyaviy_korsatkichRu'



import ElonRu from './pages/ElonRu'
import Tarix from './pages/Tarix'
import TarixRu from './pages/TarixRu'
import Afillangan from './pages/Afillangan'
import AfillanganRu from './pages/AfillanganRu'
import Eror from './pages/Eror'
import './Eror.css'
import Vakantsiya from './pages/Vakantsiya'
import VakantsiyaRu from './pages/VakantsiyaRu'
import Hisobotlar from './pages/Hisobotlar'
import HisobotlarRu from './pages/HisobotlarRu'
import '@n3/react-vision-panel/dist/vision-panel.css';


export default class App extends Component {
  componentDidMount() {
    localStorage.setItem('theme','white')
    localStorage.setItem('textTheme','black')
    localStorage.setItem('hover','blue')
  
  }
  render() {
    return (
      <div>

        <BrowserRouter>
         
        <Header/>
        <Navbar style={{width:'100%'}}/>
        <Row>
        <Col lg={3} className="K" style={{borderRight:'1px solid lightgrey',  overflowY:'auto',position:"sticky", top:'60px'}}>

  <News/>
        </Col>
        <Col lg={3} className="D"  style={{backgroundColor:'#96baf117'}}>

  <News/>
        </Col>
        <Col lg={9} style={{backgroundColor:'#96baf117'}}>
         <Switch>
         <Route exact path="/">
           <Dashboard/>
           </Route>
           
           <Route exact path="/uz">
           <Dashboard/>
           </Route>
            <Route exact path="/ru">
           <DashboardRu/>
           </Route>
           <Route exact path="/strategiya/uz">
           <Strategiya/>
           </Route>
           <Route exact path="/strategiya/ru">
           <StrategiyaRu/>
           </Route>
           <Route exact path="/elon/uz">
           <Elon/>
           </Route>
           <Route exact path="/elon/ru">
           <ElonRu/>
           </Route>
           
           <Route exact path="/bugalteriya/uz">
           <Bugalter/>
           </Route>
            <Route exact path="/bugalteriya/ru">
           <BugalterRu/>
           </Route>
           <Route exact path="/konkurs/uz">
           <Konkurslar/>
           </Route>
           <Route exact path="/konkurs/ru">
           <KonkurslarRu/>
           </Route>
           <Route exact path="/bo'lim/uz">
           <Bolimlar/>
           </Route>
           <Route exact path="/vakantsiya/uz">
           <Vakantsiya/>
           </Route>
           <Route exact path="/vakantsiya/ru">
           <VakantsiyaRu/>
           </Route>
           <Route exact path="/hisobotlar/uz">
           <Hisobotlar/>
           </Route>
           <Route exact path="/hisobotlar/ru">
           <HisobotlarRu/>
           </Route>
                
           <Route exact path="/bo'lim/ru">
           <BolimlarRu/>
           </Route>
           <Route exact path="/boshqaruv/uz">
           <Boshqaruv/>
           </Route>
           
           <Route exact path="/boshqaruv/ru">
           <BoshqaruvRu/>
           </Route>
           
           <Route exact path="/tuzilmalar/uz">
           <Tashkiliy_tuzilma/>
           </Route>
           
           <Route exact path="/tuzilmalar/ru">
           <Tashkiliy_tuzilmaRu/>
           </Route>
           
           <Route exact path="/moliya/uz">
           <Moliyaviy_korsatkich/>
           </Route>
           
           <Route exact path="/moliya/ru">
           <Moliyaviy_korsatkichRu/>
           </Route>
           <Route exact path="/tarix/uz">
           <Tarix/>
           </Route>
           
           <Route exact path="/tarix/ru">
           <TarixRu/>
           </Route>
           
           <Route exact path="/afillik/uz">
           <Afillangan/>
           </Route>
           
           <Route exact path="/afillik/ru">
           <AfillanganRu/>
           </Route>
           
        
           
           <Route exact path="/ijro_organlari/uz">
           <Ijro/>
           </Route>
           <Route exact path="/aksiya/uz">
           <Aksiya/>
           </Route>
           <Route exact path="/aloqalar/uz">
           <Aloqa/>
           </Route>
         
           <Route exact path="/faktlar/uz">
           <Fakt/>
           </Route>
           <Route exact path="/kengash/uz">
           <Kengash/>
           </Route>
           <Route exact path="/xujjatlar/uz">
           <Xujjatlar/>
           </Route>
           
           <Route exact path="/ijro_organlari/ru">
           <IjroRu/>
           </Route>
           <Route exact path="/aksiya/ru">
           <AksiyaRu/>
           </Route>
           <Route exact path="/aloqalar/ru">
           <AloqaRu/>
           </Route>
         
           <Route exact path="/faktlar/ru">
           <FaktRu/>
           </Route>
           <Route exact path="/kengash/ru">
           <KengashRu/>
           </Route>
           <Route exact path="/xujjatlar/ru">
           <XujjatlarRu/>
           </Route>
           <Route path=''>
           <Eror/>
           </Route>
           
           
           
           
                
            </Switch>
         </Col>
       
        </Row>
        <Footer/>
        </BrowserRouter>
         
      </div>
    )
  }
}
