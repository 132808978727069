import React, { Component } from 'react'
import styles from './css/about.module.css'
import {Container,Row,Col} from 'react-bootstrap'
export default class About1Ru extends Component {
    render() {
        return (
           <div>
           <Container fluid className={styles.aboutOuter}>
             <Row>
                 <Col lg={12}>
                 <div className={styles.aboutIndex}>
                    <h2>О нас</h2>
                    <p>Предприятие существует с начала ввода в эксплуатацию первых месторождений нефти и газа, расположенных в Бухарской области (1959 год). Основная цель создания предприятия - проведение исследовательских работ, направленных на совершенствование технологии добычи,  подготовки к транспорту и транспорта нефти, газа и газового конденсата. В 1964 году предприятие под наименованием «Центральная научно-исследовательская лаборатория» становится структурной единицей ПО «Бухаранефтегаз»</p>
                    <p>В 1995  году предприятие преобразовано в Открытое акционерное общество «Нефтегазисследование».</p>
                </div>
                 </Col>
             </Row>
           </Container>
           </div>
        )
    }
}
