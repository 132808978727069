import React, { Component } from 'react'
import Header1Ru from './header1Ru'
import Homiy1Ru from './homiy1Ru'
import About1Ru from './about1Ru'
import Afzallik1Ru from './afzallik1Ru'
import HashLoader from "react-spinners/HashLoader";

export default class Dashboard extends Component {
    state={
      loading:true,
    }
    
    componentDidMount(){
        this.setState({
            loading:false
        })
    }

    render() {
        return (
            <div>
                           {
              this.state.loading?<div  style={{position:"fixed", zIndex:'344322', backgroundColor:'white', display:'flex', justifyContent:'center', alignItems:'center', top:'0px', left:'0px', width:'100%', height:'100%'}} >

              <HashLoader color="blue" loading={this.state.loading} size={100} css={{zIndex:'432323'}}/> 
                          
                      </div>:
 <div>        
      <Header1Ru/>
         <Afzallik1Ru/>
        <About1Ru/>
        <Homiy1Ru/>
            </div>
    }
    </div>
)
    }
}
