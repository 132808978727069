import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import style from '../css/Header.module.css'
import logo from '../img/LOGO_NGI.png'
import koz from '../img/koz.png'
import uz from '../img/uz.gif'
import ru from '../img/ru.gif'
import { SettingsModal } from '@n3/react-vision-panel';

export default class Header extends Component {
    state={
        show:false
    }
    onShow=()=>{
        this.setState({show:true})
    }
    onHide=()=>{
        this.setState({show:false})
    }
loc=(til)=>{
    var str=window.location.href
    if(str[str.length-1]=='z' || str[str.length-1]=='u'){
        var a=str.slice(0, str.length-3)
        window.location.href=a+'/'+til
    
    }
    else{
        window.location.href+=til
    
    }
    }


    render() {
        return (
            <div>
              <div className={style.header} style={{zIndex:"0"}}>
                <div className={style.whiteBack}></div>
                <div className={style.row}>
                      <Row style={{width:'102%'}}>
                          <Col lg={3} sm={12}>
<div className={style.headerImg}>
<div  className={style.img}>
<img alt="..." src={logo}/></div>

</div>
                          </Col>
                          <Col className={style.text} lg={6}>
    {
         window.location.href.split('/')[window.location.href.split('/').length-1]==='uz' || window.location.href.split('/')[window.location.href.split('/').length-1]===''?
        
        <div className={style.headerText} style={{backgroundColor:'white', zIndex:'2',}}>
         
         <h1>Neftgaztadqiqot</h1>
<h4>aksiyadorlik jamiyati</h4></div>:
    <div className={style.headerText} style={{backgroundColor:'white', zIndex:'2',}}>
    <h1>Нефтегазисследование</h1>
   <h4>акционерное общество</h4></div>
    }

                          </Col>
                          <Col className={style.in} lg={3}>
                             <div className={style.headerInput}>
                             <form>
  <input type="search"/>
  <i className="fa fa-search"></i>
</form>

                                                         </div>
                            
                          </Col>
                      </Row>
                      <div className={style.til}>
<img alt="..." onClick={()=>{this.loc('uz')}} src={uz}/><img alt="..."  onClick={()=>{this.loc('ru')}} src={ru}/>
<img alt="..." onClick={()=>{this.onShow()}} src={koz}/>


</div>
                      </div>
                  </div>
                 <SettingsModal
                 style={{zIndex:'8567'}}
                 show={this.state.show}
                 onHide={this.onHide}/>
            </div>
        )
    }
}
