import React, { Component } from 'react'
import style from "../css/Aloqa.module.css"
import {Form, Button} from 'react-bootstrap' 
import GoogleMapReact from 'google-map-react';
import HashLoader from "react-spinners/HashLoader";
import { YMaps, Map, Placemark } from 'react-yandex-maps';

export default class Aloqa extends Component{
  state={
    loading:true,
  }
  
  componentDidMount(){
     
      
setTimeout(()=>{
  this.setState({
    loading:false
})
}, 1000);
  }

render(){
  return(
	<div className={style.cont}>
       {
              this.state.loading?<div  style={{position:"fixed", zIndex:'432323', backgroundColor:'white', display:'flex', justifyContent:'center', alignItems:'center', top:'0px', left:'0px', width:'100%', height:'100%'}} >

              <HashLoader color="blue" loading={this.state.loading} size={100} css={{zIndex:'432323'}}/> 
                          
                      </div>:
         <div>
<div className={style.row}>
  <div className={style.col2}>
  <table>
    <tr>
      <td colSpan={2}>
         <h5>Bog'lanish uchun</h5>
      </td>
    </tr>
      <tr>
        <td>
          <b>Telefon : </b>
        </td>
        <td>
          (65)221-20-20
        </td>
      </tr>
    <tr>
      <td>
        <b>Faks : </b>
      </td>  
      <td>
      (65)221-20-20
      </td>  
    </tr> 
    <tr>
      <td>
        <b>E-mail : </b>
      </td>  
      <td>
         info@aongi.uz
      </td>  
    </tr> 
    <tr>
      <td>
        <b>Ishonch telefon:</b>
      </td>  
      <td>
       (65)223-31-38
      </td>  
    </tr>     
    </table>
<br/>
    <table>
      <tr>
        <td colSpan={2}>
          <h5>Bank rekvizitlari</h5>
        </td>
      </tr>
      <tr>
        <td className={style.xisob}><b>Xisob raqami:</b></td>
        <td> 20 210 000 700 286 547 001</td>
      </tr>
      <tr><td><b>Bank :</b></td><td>O'zSQB, Buxoro hududiy filiali</td></tr>
      <tr>
        <td><b>Bank kodi : </b></td>
        <td> 00086</td>
      </tr>
      <tr>
        <td><b>STIR : </b></td>
        <td>201188456</td>
      </tr>
      <tr>
        <td><b>XXTUT : </b></td>
        <td> 95120</td>

      </tr>
      <tr>
        <td><b>IFUT : </b></td>
        <td>72190</td>
      </tr>
    </table>
  </div>
  <div  className={style.col3}>
  <Form>
  <Form.Group controlId="exampleForm.ControlInput">
    <Form.Label>Ismingiz</Form.Label>
    <Form.Control type="text" />
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>E-mail yoki telefon raqamingiz <br/>(qayta aloqa ichun)</Form.Label>
    <Form.Control type="text" />
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlTextarea1">
    <Form.Label>Xabar matni</Form.Label>
    <Form.Control as="textarea" rows={3} />
  </Form.Group>
  <Button variant="primary" type="submit">
    Xabar jo'natish
  </Button>
</Form>
  </div>

</div>
<div className={style.col1}>

  <YMaps>
    <div>
    
      <Map  style={{width:'100%', height:'300px'}} defaultState={{ center: [39.774138, 64.431248], zoom: 9 }} >
      <Placemark geometry={[39.774138, 64.431248]} />
      </Map>
    </div>
  </YMaps>
  </div>
  
</div>}
</div>
  )
}
}