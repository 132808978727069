import React, { Component } from 'react'
import style from '../css/Fakt.module.css'



import HashLoader from "react-spinners/HashLoader";

import { getNoun } from '../host/Config'
import { url } from '../host/Host'

export default class Bugalter extends Component {
state={
  accountant:[],
  loading:true,
  theme:'',
textTheme:'',
hover:'',

}
  download=(link)=>{
   window.location.href=link
}


componentDidMount(){
  this.setState({
    theme:localStorage.getItem('theme'),
    textTheme:localStorage.getItem('textTheme'),
    hover:localStorage.getItem('hover'),
  })
  getNoun().then(res=>{
   this.setState({
     accountant: res.data,
     loading:false

   })
  }).catch(err=>{
    console.log(err)
  })
}
    render() {
        return (
            <div style={{paddingTop:'40px'}}>
            {
              this.state.loading?<div  style={{position:"fixed", zIndex:'344322', backgroundColor:'white', display:'flex', justifyContent:'center', alignItems:'center', top:'0px', left:'0px', width:'100%', height:'100%'}} >

              <HashLoader color="blue" loading={this.state.loading} size={100} css={{zIndex:'432323'}}/> 
                          
                      </div>:
	<div className={style.body}>
    <h2 className={style.sarlavha}>Информация, подлежащая раскрытию АО «Нефтегаз Исследования»:</h2>
	<div className="blueLine"></div>
		<div className={style.container}>

  <ul className={style.responsiveT}>
  {
                           this.state.accountant && Array.isArray(this.state.accountant) && this.state.accountant!=[]?this.state.accountant.map(item=>{
       return(

<li onClick={()=>{this.download(`${item.hashCode}`)}} className={style.tableR}><span>
    {item.date} - Существенный факт - {item.descriptionRu}</span>
    <i class="fa fa-download" aria-hidden="true"></i>
    </li>
       )
                           }):<div className="nodata">
                           <h1>Нет информации</h1>
                     </div>
                         }

    
 </ul>
</div>
</div>}
</div>
  )
}
}