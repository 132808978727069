import React, { Component } from 'react'
// import style1 from '../css/App.module.css'
import HashLoader from "react-spinners/HashLoader";
import style from '../css/Strategiya.module.css'
export default class Strategiya extends Component {
    state={
        loading:true,
    }  
    
    componentDidMount(){
        this.setState({
            loading:false
        })
    }
    render() {
        return (
            <div>
                 {
              this.state.loading?<div  style={{position:"fixed", zIndex:'344322', backgroundColor:'white', display:'flex', justifyContent:'center', alignItems:'center', top:'0px', left:'0px', width:'100%', height:'100%'}} >

              <HashLoader color="blue" loading={this.state.loading} size={100} css={{zIndex:'432323'}}/> 
                          
                      </div>:
                      <div className={style.back}>
                          <div className={style.qora}></div>
<p className={style.p}>
    <h4>"Neftgaztadkikot" OAJni rivojlantirish strategiyasi</h4>
    <p>Hozirgi vaqtda "Neftgaztadqiqot" OAJ o'z imkoniyatlarini faol ravishda kengaytirmoqda va tadqiqotlar sifatini oshirmoqda. Amaldagi va kelajakdagi sheriklarining ehtiyojlarini to'liq qondirish uchun kompaniya o'z faoliyatida yangi yo'nalishlarni ochadi.</p>
    <p>О'z oldiga qo'yilgan maqsadlarga erishish uchun, "Neftgaztadqiqot" AJning strategik maqsadlari bo'yicha quyidagi yo'nalishlar bo'yicha rivojlanishdan iborat:</p>
<ul>
<li>Zamonaviy innovatsion texnologiya qo'llanishi bilan, amalga oshirilgan tadqiqot ishlarining natijalari aniqligi va sifatini oshirish;</li>
<li>O'zbekiston respublikasi neft va gaz tarmog'ida amalga oshirilgan tadqiqot ishlarida sog'lom raqobat muhitini qullab-quvvatlash;</li>
<li>Tadqiqot ishlarini amalga oshirishda zharayonlarni bosqichma-boschich avtomatlashtirish, axborot-kommunikatsiya texnologiyalari qullanishini zhadal rivozhifikatsiya qilish;</li>
<li>Jamiyat muhandis-texnik va ishchi xodimlarni tayyorlash va malakasini oshirish;</li>
<li>Xorijiy va mahalliy investorlarni hamkorlikka faol jalb qilish;</li>
</ul>
</p>

                      </div>
                      
                      
                      }
            </div>
        )
    }
}
