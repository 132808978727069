import React, { Component } from 'react'
import styles from '../css/Konkurslar.module.css'
import {Container,Row,Col} from 'react-bootstrap'
import {BellOutlined,FormOutlined,DownloadOutlined} from '@ant-design/icons'
import { Button, Radio  } from 'antd';
import HashLoader from "react-spinners/HashLoader";

import { getTenders } from '../host/Config'
import { url } from '../host/Host';

export default class Bugalter extends Component {
state={
  accountant:[],
  loading:true,
  theme:'',
textTheme:'',
hover:'',

}
  download=(link)=>{
   window.location.href=link
}


componentDidMount(){
  this.setState({
    theme:localStorage.getItem('theme'),
    textTheme:localStorage.getItem('textTheme'),
    hover:localStorage.getItem('hover'),
  })
  getTenders().then(res=>{
   this.setState({
     accountant: res.data,
     loading:false

   })
   console.log(this.state.accountant)
  }).catch(err=>{
    console.log(err)
  })
}
    render() {
        return (
            <div style={{paddingTop:'40px'}}>
            {
              this.state.loading?<div  style={{position:"fixed", zIndex:'344322', backgroundColor:'white', display:'flex', justifyContent:'center', alignItems:'center', top:'0px', left:'0px', width:'100%', height:'100%'}} >

              <HashLoader color="blue" loading={this.state.loading} size={100} css={{zIndex:'432323'}}/> 
                          
                      </div>:
                <div className={styles.konkurslar}>
                    <h1>Тендеры</h1>
                    <Container fluid className={styles.containerKonkurslar} style={{marginTop:"40px"}}>
                        <Row>
                        {
                           this.state.accountant && Array.isArray(this.state.accountant) && this.state.accountant!=[]?this.state.accountant.map(item=>{

                 return(          <Col xl={4} lg={6} md={6} sm={6}>
                    <div className={styles.cardsKonkurslar}>
                        
                        <h2 style={{fontSize:'20px'}}>Приглашаем принять участие в конкурсе :</h2>                   
                        <p style={{fontSize:'16px'}}>{item.titleRu}</p>

                        <span style={{position:'relative', top:'-30px'}}>Дата публикации:</span>
                        <h6 style={{position:'relative', top:'-30px'}}><FormOutlined style={{color:'hsl(212, 86%, 64%)',fontSize:'18px', position:'relative', top:'-4px', left:'-3px', marginRight:'0px'}}/>  
                        {item.startDate} год</h6>
                        <span style={{position:'relative', top:'-30px', fontSize:'12px'}}>Дата окончания конкурса:</span> 
                        <h6 style={{position:'relative', top:'-30px'}}><BellOutlined style={{color:'hsl(212, 86%, 64%)',fontSize:'18px', position:'relative', top:'-4px',left:'-3px', marginRight:'0px'}}/> 
                        {item.finishedDate} год до 16:00</h6>
                        <br/>
                        <Button onClick={()=>{this.download(`${item.hashCode}`)}} style={{marginTop:'-20px'}} type="primary" 
                        icon={<DownloadOutlined style={{color:'white',fontSize:'20px', position:'relative', top:'-4px'}}/>} 
                        className={styles.btnKonkurslar} size={this.state.size}><span style={{color:'white', fontSize:'14px'}}> 
                        Скачать Pdf</span></Button>
                        
                    </div>
                </Col> 
                  ) 
                
                }):''
               }
                        </Row>
                    </Container>
                </div>
    }
            </div>
        )
    }
}
