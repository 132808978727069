import React, { Component } from 'react'
import styles from '../css/News.module.css'
import image from '../img/ger.jpg'
import neftegaz from '../img/6fb78d0d6424120ad77eb.jpg'
import Carousel from "react-multi-carousel";
import workers2 from '../img/a28c0fc4c89729ed04842.jpg'
import "react-multi-carousel/lib/styles.css";
import workers from '../img/avKUImR_W4RYXkIAXldMWx47qgo03FYo.jpg'
import { getNews } from '../host/Config'
import { url } from '../host/Host'
import HashLoader from "react-spinners/HashLoader";
import { Button } from 'antd';

export default class News extends Component {
  state={
    accountant:[],
    loading:true,
    theme:'',
  textTheme:'',
  hover:'',
  
  }
    download=(link)=>{
     window.location.href=link
  }
  componentDidMount(){
    getNews().then(res=>{
      this.setState({
        accountant: res.data,
        loading:false
   
      })
     }).catch(err=>{
       console.log(err)
     })
   }
    
  loc=(a)=>{
window.location.href=a
  }
   
  render() {

        const responsive = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 993 },
              items: 1
            },
            desktop: {
              breakpoint: { max: 992, min: 769 },
              items: 2
            },
            tablet: {
              breakpoint: { max: 768, min: 567 },
              items: 2
            },
            mobile: {
              breakpoint: { max: 566, min: 0 },
              items: 1
            }
          }
        return (
        <div>
           {this.state.loading?
//            <div  style={{position:"fixed", zIndex:'544322', backgroundColor:'white', display:'flex', justifyContent:'center', alignItems:'center', top:'0px', left:'0px', width:'100%', height:'100%'}} >

// <HashLoader color="blue" loading={this.state.loading} size={100} css={{zIndex:'444322'}}/> 
            
//         </div>
<div></div>
        :

        <div className={styles.newsHeader} id="newsH" style={{zIndex:'-4'}}>
           {window.location.href.split('/')[window.location.href.split('/').length-1]==='uz' || window.location.href.split('/')[window.location.href.split('/').length-1]===''?
<div  style={{marginLeft:'0px', marginTop:'30px'}}>

               <Carousel responsive={responsive} infinite={true}
                 autoPlay={this.props.deviceType !== "mobile" ? true : false}
                 autoPlaySpeed={3000}
                 keyBoardControl={true}
                 responsive={responsive}
                 >
              
              {this.state.accountant && Array.isArray(this.state.accountant) && this.state.accountant!=[]?this.state.accountant.map(item=>{
                return(
                
                <div>
              
                <div className={styles.cardNews}>
                              <img src={`${item.hashCode}`}/>
                              {/* <span className={styles.dateNews}><i className="fa fa-clock"></i> {}</span>
                              */}
                        <div className={styles.chiziq}></div>      
                        <div className={styles.her}><h2>{item.descriptionUz}</h2></div>
         
                 </div>
                </div>
               )
              }):''}
                              
               </Carousel></div>:<div  style={{marginLeft:'0px', marginTop:'30px'}}>  
                  
                    <Carousel responsive={responsive} infinite={true}
                      autoPlay={this.props.deviceType !== "mobile" ? true : false}
                      autoPlaySpeed={3000}
                      keyBoardControl={true}
                      responsive={responsive}
                      >
                   
                   {this.state.accountant && Array.isArray(this.state.accountant) && this.state.accountant!=[]?this.state.accountant.map(item=>{
                     return(
                     
                     <div>
                   
                     <div className={styles.cardNews}>
                                   <img src={`${item.hashCode}`}/>
                                   {/* <span className={styles.dateNews}><i className="fa fa-clock"></i> {}</span>
                                   */}
                                   <div className={styles.chiziq}></div>
                                   <div className={styles.her}><h2>{item.descriptionRu}</h2></div>
              
                      </div>
                     </div>
                    )
                   }):''}
                                   
                    </Carousel>
</div>        
    
          }
                       </div>     }</div>
        )
    }
}
