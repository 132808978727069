import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import style from '../css/Elon.module.css' 
import HashLoader from "react-spinners/HashLoader";
import { getElon } from '../host/Config'
import { url } from '../host/Host'

export default class Elon extends Component {
    state={
        accountant:[],
        loading:true,
        theme:'',
      textTheme:'',
      hover:'',
      
      }
        download=(link)=>{
         window.location.href=link
      }
      
      
      componentDidMount(){
        this.setState({
          theme:localStorage.getItem('theme'),
          textTheme:localStorage.getItem('textTheme'),
          hover:localStorage.getItem('hover'),
        })
        getElon().then(res=>{
        
            this.setState({
           accountant: res.data,
           loading:false
      
         })
        }).catch(err=>{
          console.log(err)
        })
      }
      
    render() {
        return (
            <div>
                  {
              this.state.loading?<div  style={{position:"fixed", zIndex:'344322', backgroundColor:'white', display:'flex', justifyContent:'center', alignItems:'center', top:'0px', left:'0px', width:'100%', height:'100%'}} >

              <HashLoader color="blue" loading={this.state.loading} size={100} css={{zIndex:'432323'}}/> 
                          
                      </div>: 
                                 <div className={style.elon}>
                      <Container>
                                 <ul>
                  
                    
                        
                         {
                           this.state.accountant && Array.isArray(this.state.accountant) && this.state.accountant!=[]?this.state.accountant.map(item=>{
       return(    <li>

        <div>                            <p className={style.clock}><i class="fa fa-clock" aria-hidden="true"></i>  {item.date} год</p>
                                    <hr/>
                                    <p className={style.ned} >{item.titleRu}</p>
                                    <hr/>
                                    <p onClick={()=>{this.download(`${item.hashCode}`)}} className={style.down}><i className="fa fa-download"></i></p>
                                </div></li>)
                           }):''

                      
                        }
                
                </ul>
                </Container>
                </div>
    }
                    
                
                     
               
            </div>
        )
    }
}
