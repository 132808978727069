import React, { Component } from 'react'
import style from "../css/Aloqa.module.css"
import {Form, Button} from 'react-bootstrap' 
import GoogleMapReact from 'google-map-react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';


const AnyReactComponent = ({ text }) => <div>{text}</div>;
export default class AloqaRu extends Component{

render(){
  return(
	<div className={style.cont}>
    
<div className={style.row}>
  
  <div className={style.col2}>
  <table>
    <tr>
      <td colSpan={2}>
         <h5>Для подключения</h5>
      </td>
    </tr>
      <tr>
        <td>
          <b>Телефон : </b>
        </td>
        <td>
          (65)221-20-20
        </td>
      </tr>
    <tr>
      <td>
        <b>Факс : </b>
      </td>  
      <td>
      (65)221-20-20
      </td>  
    </tr> 
    <tr>
      <td>
        <b>E-mail : </b>
      </td>  
      <td>
         info@aongi.uz
      </td>  
    </tr> 
    <tr>
      <td>
        <b>Телефон доверия:</b>
      </td>  
      <td>
       (65)223-31-38
      </td>  
    </tr>     
    </table>
<br/>
    <table>
      <tr>
        <td colSpan={2}>
          <h5>Банковские реквизиты</h5>
        </td>
      </tr>
      <tr>
        <td className={style.xisob}><b>Расчетный счет:</b></td>
        <td> 20 210 000 700 286 547 001</td>
      </tr>
      <tr><td><b>Банк :</b></td><td> в региональном филиале УзПСБ, г.Бухара</td></tr>
      <tr>
        <td><b>Код банка : </b></td>
        <td> 00086</td>
      </tr>
      <tr>
        <td><b>ИНН : </b></td>
        <td>201188456</td>
      </tr>
      <tr>
        <td><b>ОКОНХ : </b></td>
        <td> 95120</td>

      </tr>
      <tr>
        <td><b>ОКЭД : </b></td>
        <td>72190</td>
      </tr>
    </table>
  </div>
  <div  className={style.col3}>
  <Form>
  <Form.Group controlId="exampleForm.ControlInput">
    <Form.Label>Ваше имя:</Form.Label>
    <Form.Control type="text" />
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>Ваш e-mail или телефон <br/>(для ответа):</Form.Label>
    <Form.Control type="text" />
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlTextarea1">
    <Form.Label>Ваше сообщение:</Form.Label>
    <Form.Control as="textarea" rows={3} />
  </Form.Group>
  <Button variant="primary" type="submit">
  Отправить сообщениe
  </Button>
</Form>
  </div>

</div>
<div className={style.col1}>

  <YMaps>
    <div>
     
      <Map  style={{width:'100%', height:'300px'}} defaultState={{ center: [39.774138, 64.431248], zoom: 9 }} >
      <Placemark geometry={[39.774138, 64.431248]} />
      </Map>
    </div>
  </YMaps>
</div></div>
  )
}
}