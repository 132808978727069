import React, { Component } from 'react'
import styles from './css/header.module.css'
import { Carousel } from 'antd';


export default class Header1 extends Component {
    render() {
        const contentStyle = {
            height: '160px',
            color: '#fff',
            lineHeight: '160px',
            textAlign: 'center',
            background: '#364d79',
          }
        return (
            <div>
                <Carousel autoplay
                >
                    <div className={styles.sliderDiv}>
                    
                    <div className={styles.sliderInsideDiv}>
                       <h1>"NEFTGAZTADQIQOT" AJ</h1>
                       <h2>1959-yildan beri</h2>
                       <p> Korxona Buxoro viloyati hududida ilk neft va gaz konlari ishga tushurish asnosida o'z faoliyatini boshlahgan.</p>
                    </div>
                    </div>
                    <div className={styles.sliderDiv,styles.slider2Div}>
                    <div className={styles.sliderInsideDiv}>
                       <h1>"NEFTGAZTADQIQOT" AJ</h1>
                       <h2>1964-yildan</h2>
                       <p>Korxona "Markaziy ilmiy - tadqiqot laboratoriyasi" nomi bilan "Buxoroneftgaz" ishlab chiqarish brilashmasining tarkibiga kiritildi.</p>
                    </div>
                    </div>
                    <div className={styles.sliderDiv,styles.slider3Div}>
                    <div className={styles.sliderInsideDiv}>
                       <h1>"NEFTGAZTADQIQOT" AJ</h1>
                       <h2>1995-yilda</h2>
                       <p>Korxonaning mulkchilik shakli o'zgartirilib, "Neftgaztadqiqot" ochiq aksiyadorlik jamiyatiga aylantirildi.</p>
                    </div>
                    </div>
                    <div className={styles.sliderDiv,styles.slider4Div}>
                    <div className={styles.sliderInsideDiv}>
                       <h1>"NEFTGAZTADQIQOT" AJ</h1>
                       
                       <p>"Neftgaztadqiqot" AJ "Uzbekneftgaz" milliy holding kompaniyasining neft va gaz qazib chiqaruvchi korxonlari konlarida  faoliyat yuritadi.</p>
                       
                    </div>
                    </div>
                </Carousel>
            </div>
        )
    }
}
