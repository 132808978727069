import { httpRequest, url } from "./Host"

export const getAccountant=()=>{
    var config={
        url:`${url}/accountant/`,
        method:'GET',
    }
    return(httpRequest(config))
}
export const getElon=()=>{
    var config={
        url:`${url}/advertisement/`,
        method:'GET',
    }
    return(httpRequest(config))
}
export const getNews=()=>{
    var config={
        url:`${url}/news/`,
        method:'GET',
    }
    return(httpRequest(config))
}
export const getTenders=()=>{
    var config={
        url:`${url}/tenders/`,
        method:'GET',
    }
    return(httpRequest(config))
}
export const getNoun=()=>{
    var config={
        url:`${url}/noun/`,
        method:'GET',
    }
    return(httpRequest(config))
}