import React, { Component } from 'react'
import style from '../css/xujjatlar.module.css'
import icon1 from '../img/dl.svg'
import icon2 from '../img/pdf.png'
import q from '../pdf/1.pdf'
import q2 from '../pdf/1.pdf'
import q3 from '../pdf/3.pdf'
import q4 from '../pdf/4.pdf'
import q5 from '../pdf/5.pdf'
import q6 from '../pdf/6.pdf'
import q7 from '../pdf/7.pdf'
import q8 from '../pdf/8.pdf'
import q9 from '../pdf/9.pdf'
import q10 from '../pdf/10.pdf'
import q11 from '../pdf/11.pdf'
import q12 from '../pdf/1.pdf'
import q13 from '../pdf/13.pdf'
import q14 from '../pdf/14.pdf'
import q15 from '../pdf/15.pdf'
import q16 from '../pdf/16.pdf'
import q17 from '../pdf/17.pdf'
import q21 from '../pdf/21.pdf'
import q22 from '../pdf/22.pdf'
import q23 from '../pdf/23.pdf'
import q20p from '../pdf/20.pdf'
import q18 from '../pdf/18.xlsx'
import q19 from '../pdf/19.pdf'
import q20 from '../pdf/20.docx'
import q24 from '../pdf/Баланс гадовой Форма -1 2023г.pdf'
import q25 from '../pdf/Баланс годовой Форма-2  2023г.pdf'
import q26 from '../pdf/Баланс форма 1.pdf'
import q27 from '../pdf/Баланс поли год форма 2.pdf'
export default class Xujjatlar extends Component{


render(){
  return(

<div className={style.containerGr}>
  <h5 className={style.aj}>Внутренние документы АО «Нефтегаз исследование»:</h5>
	<div className="blueLine"></div>
  <h2 className={style.dbr}>Cкачать документы:</h2>
  <div className={style.hhh}>
  <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Форма бухгалтерского баланса №1 2024 год</span></h3>
</div>
			<a download target="_blank" href={q26} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Форма бухгалтерского баланса №2 2024 год</span></h3>
</div>
			<a download target="_blank" href={q27} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
  <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Форма бухгалтерского баланса №1 2023 год</span></h3>
</div>
			<a download target="_blank" href={q24} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Форма бухгалтерского баланса №2 2023 год</span></h3>
</div>
			<a download target="_blank" href={q25} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
  <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Форма №1 отчета о финансовых результатах</span></h3>
</div>
			<a download target="_blank" href={q20p} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Форма №2 отчета о финансовых результатах</span></h3>
</div>
			<a download target="_blank" href={q21} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Форма бухгалтерского баланса №1</span></h3>
</div>
			<a download target="_blank" href={q22} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Форма бухгалтерского баланса №2</span></h3>
</div>
			<a download target="_blank" href={q23} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
		<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Бизнес-план на 2022 год</span></h3>
</div>	
			<a download target="_blank" href={q19} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
		<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Структура общества</span></h3>
</div>	
			<a download target="_blank" href={q20} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
		<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Устав общества</span></h3>
            <p><i>(Документ на узб.языке)</i></p></div>	
			<a download target="_blank" href={1} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
   
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}> ПОЛОЖЕНИЕ
О ОБЩЕМ СОБРАНИИ
АКЦИОНЕРОВ
АКЦИОНЕРНОГО ОБЩЕСТВА
«НЕФТЕГАЗ ИССЛЕДОВАНИЕ»</span></h3>
</div>
			<a download target="_blank" href={q3} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>ПОЛОЖЕНИЕ
О НАБЛЮДАТЕЛЬНОМ СОВЕТЕ
АКЦИОНЕРОГО ОБЩЕСТВА
«НЕФТЕГАЗ ИССЛЕДОВАНИЕ»</span></h3>
			</div>

			<a download target="_blank" href={q4} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>ПОЛОЖЕНИЕ
ОБ ИСПОЛНИТЕЛЬНОМ ОРГАНЕ
АКЦИОНЕРНОГО ОБЩЕСТВА
«НЕФТЕГАЗ ИССЛЕДОВАНИЕ»</span></h3>
			
</div>
			<a download target="_blank" href={q5} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>ПОЛОЖЕНИЕ
О РЕВИЗОРЕ.
АКЦИОНЕРНОГО ОБЩЕСТВА
«НЕФТЕГАЗ ИССЛЕДОВАНИЕ»
</span></h3>
</div>
			<a download target="_blank" href={q6} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>"Нефтегазисследование"
акционерная компания
Положение о внутреннем аудите</span></h3>
			<p><i>(На узб.языке)</i></p>
      </div>
			<a download target="_blank" href={q7} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>ПОЛОЖЕНИЕ
О ВНУТРЕННЕМ КОНТРОЛЕ
АКЦИОНЕРНОГО ОБЩЕСТВА
«НЕФТЕГАЗ ИССЛЕДОВАНИЕ»</span></h3>
			
</div>
			<a download target="_blank" href={q8} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>ПОЛОЖЕНИЕ
ОБ ИНФОРМАЦИОННОЙ ПОЛИТИКЕ
АО «НЕФТЕГАЗ ИССЛЕДОВАНИЕ»</span></h3>
			
</div>
			<a download target="_blank" href={q9} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
        <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>
            ПОЛОЖЕНИЕ
О ДИВИДЕНДНОЙ ПОЛИТИКЕ
АКЦИОНЕРНОГО ОБЩЕСТВА
«НЕФТЕГАЗ ИССЛЕДОВАНИЕ»
        </span>
      </h3>		
</div>
			<a download target="_blank" href={q10} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>
            ПОЛОЖЕНИЕ
О ПОРЯДКЕ ДЕЙСТВИЙ ПРИ
КОНФЛИКТЕ ИНТЕРЕСОВ
АКЦИОНЕРНОГО ОБЩЕСТВА
«НЕФТЕГАЗ ИССЛЕДОВАНИЕ»
        </span>
      </h3>		
</div>
			<a download target="_blank" href={q11} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
   
    <div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Проспект эмиссии</span></h3>
            <p><i>(На узб.языке)</i></p>
</div>
	    <a download target="_blank" href={q13} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Положительный аудиторский отчет 2020 об обществе</span></h3>
</div>
			<a download target="_blank" href={q14} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Положительный аудиторский отчет 2021 об обществе</span></h3>
</div>
			<a download target="_blank" href={q15} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Бизнес-план для общества на 2020 год</span></h3>
</div>
			<a download target="_blank" href={q16} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
		<div className={style.grid}>
			<div className={style.quti}><h2><img src={icon2}/>PDF</h2>
			<h3><span className={style.uppercase}>Бизнес-план для общества на 2021 год</span></h3>
</div>
			<a download target="_blank" href={q17} className={style.button}>Скачать<img src={icon1}/></a>
		</div>
</div>
</div>

  )
}
}
