import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import tuzilma from '../img/tuzilma.jpg'
import styles from '../css/komp_haqida.module.css'

class Tashkiliy_tuzilma extends React.Component {


    render() {
        return (
            <div>
                <Container fluid className='mt-5 '>
               <Row>
                   <Col lg={1}></Col>
                   <Col lg={10}>
                   <h3 className='text-center mb-5'>AO "Neftgaztadqiqot" AJ tashkiliy tuzilmasi</h3>
                   <div className="blueLine" style={{marginBottom:'20px'}}></div>
                <img className={styles.tuzilma} src={tuzilma} style={{marginLeft:'auto', marginRight:'auto', display:'block'}} />
               <div className={styles.text}>
               <p  style={{textIndent:'25px', textAlign:'justify'}}>
                    "Neftgaztadqiqot" AJning Oliy boshqaruv organi aksiyadorlarning Umumiy yig‘ilishi hisoblanadi.
                </p>
               <p style={{textIndent:'25px', textAlign:'justify'}}>
                   Umumiy yig‘ilish tomonidan saylanadigan Kuzatuv kengashi jamiyatning umumiy rahbarligini amalga oshirib,faoliyatning ustivor yo‘nalishlarini, rivojlanish istiqbollari va strategiyasini aniqlab beradi, hamda jamiyat oldida turgan yaqin muddatdagi maqsadlarni va ularga erishish taktikasini ishlab chiqadi.
               </p>
                 <p style={{textIndent:'25px', textAlign:'justify'}}>
                 Joriy rahbarlik kollegial ijroya organi – Boshqaruv, tomonidan amalga oshirilib, mazkur organ Umumiy yig‘ilish va Kuzatuv kengashi tomonidan qabul qilingan qarorlarning bajarilishini tashkillashtiradi va nazorat qiladi.
                 </p>
               </div>
                   </Col>
                   <Col lg={1}></Col>
               </Row>
                
                </Container>
            </div>
        );
    }
}



export default Tashkiliy_tuzilma;
