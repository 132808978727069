import img from '../img/a28c0fc4c89729ed04842.jpg'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import style from '../css/Tarix.module.css'
export default class Tarix extends Component {
    render() {
        return (
            <div className={style.odam}>
                <Container>
                    <Row>
                    <Col lg={12}> 
                  <Row>
                    <Col lg={7} md={12} className={style.img}>
    <img src={img} style={{width:'100%'}}/>
</Col>
<Col lg={5} md={12}>
    <br/><h4>Ismoilov Rahmon Zohid o'g'li</h4><div className={style.chiziq}></div>
    <p><b>Дата рождения: </b>12.03.2001 yil</p>
    <p><b>Дата приема на работу: </b>12.03.2001 - 23.04.2024</p>
    <p><b>Университет, в котором учился: </b>Ташкентский университет информационных технологий, факультет компьютерной инженерии</p>
    <p><b>Дополнительная информация: </b> Notice the use of %PUBLIC_URL% in the tags above.
      It will be replaced with the URL of the `public` folder during the build.
      Only files inside the `public` folder can be referenced from the HTML.

      Unlike "/favicon.ico" or "favicon.ico", "%PUBLIC_URL%/favicon.ico" will
      work correctly both with client-side routing and a non-root public URL.
      Learn how to configure a non-root public URL by running `npm run build`.</p>
</Col>
</Row>
</Col><Col lg={12}> 
                  <Row>
                  
<Col lg={5} md={12}>
    <br/><h4>Ismoilov Rahmon Zohid o'g'li</h4><div className={style.chiziq}></div>
    <p><b>Дата рождения: </b>12.03.2001 yil</p>
    <p><b>Дата приема на работу: </b>12.03.2001 - 23.04.2024</p>
    <p><b>Университет, в котором учился: </b>Ташкентский университет информационных технологий, факультет компьютерной инженерии</p>
    <p><b>Дополнительная информация: </b> Notice the use of %PUBLIC_URL% in the tags above.
      It will be replaced with the URL of the `public` folder during the build.
      Only files inside the `public` folder can be referenced from the HTML.

      Unlike "/favicon.ico" or "favicon.ico", "%PUBLIC_URL%/favicon.ico" will
      work correctly both with client-side routing and a non-root public URL.
      Learn how to configure a non-root public URL by running `npm run build`.</p>
</Col>
<Col lg={7} md={12} className={style.img}>
    <img src={img} style={{width:'100%'}}/>
</Col>
</Row>
</Col><Col lg={12}> 
                  <Row>
                    <Col lg={7} md={12} className={style.img}>
    <img src={img} style={{width:'100%'}}/>
</Col>
<Col lg={5} md={12}>
    <br/><h4>Ismoilov Rahmon Zohid o'g'li</h4><div className={style.chiziq}></div>
    <p><b>Дата рождения: </b>12.03.2001 yil</p>
    <p><b>Дата приема на работу: </b>12.03.2001 - 23.04.2024</p>
    <p><b>Университет, в котором учился: </b>Ташкентский университет информационных технологий, факультет компьютерной инженерии</p>
    <p><b>Дополнительная информация: </b> Notice the use of %PUBLIC_URL% in the tags above.
      It will be replaced with the URL of the `public` folder during the build.
      Only files inside the `public` folder can be referenced from the HTML.

      Unlike "/favicon.ico" or "favicon.ico", "%PUBLIC_URL%/favicon.ico" will
      work correctly both with client-side routing and a non-root public URL.
      Learn how to configure a non-root public URL by running `npm run build`.</p>
</Col>
</Row>
</Col>
                    </Row>

                </Container>
            </div>
        )
    }
}
