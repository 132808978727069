import { Container, Row, Col, Card } from 'react-bootstrap'
import React, { Component } from 'react'
import style1 from '../css/App.module.css'
import { CloudDownloadOutlined } from '@ant-design/icons'
import HashLoader from "react-spinners/HashLoader";

import { getAccountant } from '../host/Config'
import { url } from '../host/Host'

export default class Bugalter extends Component {
state={
  accountant:[],
  loading:true,
  theme:'',
textTheme:'',
hover:'',

}
  download=(link)=>{
   window.location.href=link
}


componentDidMount(){
  this.setState({
    theme:localStorage.getItem('theme'),
    textTheme:localStorage.getItem('textTheme'),
    hover:localStorage.getItem('hover'),
  })
  getAccountant().then(res=>{
   this.setState({
     accountant: res.data,
     loading:false

   })
  }).catch(err=>{
    console.log(err)
  })
}
    render() {
        return (
            <div style={{paddingTop:'40px'}}>
            {
              this.state.loading?<div  style={{position:"fixed", zIndex:'344322', backgroundColor:'white', display:'flex', justifyContent:'center', alignItems:'center', top:'0px', left:'0px', width:'100%', height:'100%'}} >

              <HashLoader color="blue" loading={this.state.loading} size={100} css={{zIndex:'432323'}}/> 
                          
                      </div>:
                         <Container>
                         <Row>
                         {
                           this.state.accountant && Array.isArray(this.state.accountant) && this.state.accountant!=[]?this.state.accountant.map(item=>{
       return(<Col lg={4} md={6} sm={12} style={{padding:'10px 20px'}}>
       <Card className={style1.bugCard} style={{backgroundColor:this.state.theme}} >
         <Card.Body>
           <Card.Text style={{color:this.state.textTheme, fontWeight:'bold'}}>
           {item.textUz} bo'yicha jamiyatning moliyaviy hisobotlari:
           </Card.Text>
           <Card.Link  onClick={()=>{this.download(`${item.accountantAttachHashCode}`)}} target="_blank" className={style1.bugLink}>Bugalteriya balansi <CloudDownloadOutlined style={{position:'absolute', right:'10px', top:'10px'}} /></Card.Link><br/>
           <Card.Link onClick={()=>{this.download(`${item.reportAttachHashCode}`)}}  target="_blank" className={style1.bugLink}>Moliyaviy natijalar <CloudDownloadOutlined style={{position:'absolute', right:'10px', top:'10px'}} /></Card.Link>
         </Card.Body>
       </Card>
       </Col>)
                           }):<div className="nodata">
                           <h1>Ma'lumot yo'q</h1>
                     </div>
                         }
                         
                              
       
       
       
                       </Row>
                         </Container>  
       
            }
            
                       </div>
        )
    }
}
