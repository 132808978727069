import React, { Component } from 'react'
import styles from '../css/Bolimlar.module.css'
import bolimlar1 from '../img/bolimlar1.jpg'
import {Container,Row,Col} from 'react-bootstrap'
import {CheckOutlined} from '@ant-design/icons'
import bolim2 from '../img/bolim2.jpg'
import bolim3 from '../img/bolim3.jpg'
import bolim4 from '../img/bolim4.jpg'
import bolim6 from '../img/bolim6.jpg'
import bolim5 from '../img/bolim5.jpg'
import { Link } from 'react-router-dom'
export default class Bolimlar extends Component {
    render() {
        return (
            <div>
                <div className={styles.bolimlar}>
                    <Container fluid className={styles.firstBolim}>
                        <Row>
                            <Col lg={12}>
                                <Row>
                                <Col lg={4} md={6} sm={12}>
                                        <Link to="/vakantsiya/uz" className={styles.butt}>
                                            Bo'sh ish o'rinlari
                                        </Link>
                                    </Col>
                                    <Col lg={4} md={6} sm={12}>
                                        <Link to="/konkurs/uz" className={styles.butt}>
                                            Tenderlar
                                        </Link>
                                    </Col>
                                    <Col lg={4} md={6} sm={12}>
                                        <Link to="/uz" className={styles.butt}>
                                            Korxona faoliyati va maqsadlari
                                        </Link>
                                    </Col>
                                    
                                    <Col lg={4} md={6} sm={12}>
                                        <Link to="/moliya/uz" className={styles.butt}>
                                            Moliyaviy ko'rsatkichlar
                                        </Link>
                                    </Col>
                                     
                                    <Col lg={4} md={6} sm={12}>
                                        <Link to="/aloqalar/uz" className={styles.butt}>
                                            Murojaat yuborish
                                        </Link>
                                    </Col>
                                    
                                    <Col lg={4} md={6} sm={12}>
                                        <Link to="/hisobotlar/uz" className={styles.butt}>
                                            Hisobotlar
                                        </Link>
                                    </Col>
                                    <Col lg={4} md={6} sm={12}>
                                        <Link to="/xujjatlar/uz" className={styles.butt}>
                                            Ichki hujjatlar
                                        </Link>
                                    </Col>
                                    <Col lg={4} md={6} sm={12}>
                                        <Link to="/afillik/uz" className={styles.butt}>
                                            Affillangan shaxslar
                                        </Link>
                                    </Col>
                                    <Col lg={4} md={6} sm={12}>
                                        <Link to="/strategiya/uz" className={styles.butt}>
                                            Rivojlanish strategiyasi
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={12}>
                            <h1>O'z faoliyatini amalga oshirish uchun jamiyatda 6 ta bo'lim tashkil etilgan</h1>
                            </Col>
                            
                            <Col lg={6} md={12} sm={12}>
                                <img src={bolimlar1}/>
                            </Col>
                            <Col lg={6} md={12} sm={12} className={styles.headingBolim}>
                                <h2>Neft va gaz konlarini tadqiq etish bo'limi neft va gaz qazib olish konlarida:</h2>
                            </Col>
                            <Col  xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar1}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> ishlatilayotgan quduqlarning joriy maxsuldorlik xarakteristikalarini</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar2}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> qazib olinayotgan gazning gaz-kondensat xarakteristikalarini</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar3}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> quduqlarning joriy suv ko'rsatgichlarini</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar4}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> quduqlarning qatlam bosimini hamda qazib olinayotgan neftning suvlanish darajasi</span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className={styles.secondBolim}>
                        <Row>
                            <Col lg={6} md={12} sm={12} className={styles.headingSecondBolim}>
                                <h1>Gazlar, gaz kondensatlari, neftlar va qatlam suvlarining xususiyatlari va tarkiblarini tadqiq etish masalasi</h1>
                            </Col>
                            <Col lg={6} md={12} sm={12} className={styles.imageSecondBolim}>
                                <img src={bolim2}/>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar5}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> qazib olinayotgan mahsulotlarning fizik-kimyoviy tahlillari</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar6}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> neft va gaz quduqlarining suvlanganligining gidro-kimyoviy nazorati bo‘yicha</span>
                                </div>
                            </Col>
                            
                        </Row>
                    </Container>
                    <Container fluid className={styles.thirdBolim}>
                        <Row>
                        
                            <Col lg={6} md={12} sm={12} className={styles.imageSecondBolim}>
                                <img src={bolim3}/>
                            </Col>
                            <Col lg={6} md={12} sm={12} className={styles.headingSecondBolim}>
                                <h1>Gazni tayyorlash, qayta ishlash va atrof muhitni muxofaza qilish bo‘limi</h1>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar7}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> tashish uchun tayyorlangan gazning sifat ko‘rsatkichlarini aniqlash</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar8}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> qazib oluvchi korxonalarining gazni tayyorlash qurilmalarida gaz-kondensat tahlillarini bajarish</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar9}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> "O‘zneftgazqazib chiqarish" AK korxonalari tomonidan naqliyot qilinayotgan gazning sifatini nazorat qilish</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar10}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> atrof-muhit, tuproq va suv havzalarining sanoat chiqindilari bilan ifloslanishini nazorat qilish</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar11}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> tabiiy gazni quritish va tozalash jarayonida foydalaniladigan seolitlarni sinovdan o‘tkazish</span>
                                </div>
                            </Col>
                            
                        </Row>
                    </Container>
                    <Container fluid className={styles.firstBolim}>
                        <Row>
                            <Col lg={6} md={12} sm={12}>
                                <img src={bolim4}/>
                            </Col>
                            <Col lg={6} md={12} sm={12} className={styles.headingBolim}>
                                <h2>Elektr-kimyoviy muhofaza bo‘limi</h2>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar1}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> yer osti quvurlarini, sanoat kommunikasiyalarini yemirilishdan muhofaza qilish</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar2}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> yer osti quvurlarining yemirilishdan himoya qoplamalari shikastlangan joylarini aniqlash</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar3}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> tuproqning metallni yemirilishi xavfi yuqori bo‘lgan joylarini aniqlash</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar4}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> yemirilishdan muhofaza qiluvchi qurilmalar uchun eng maqbul himoya toklarini aniqlash</span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className={styles.secondBolim}>
                        <Row>
                            <Col lg={6} md={12} sm={12} className={styles.headingSecondBolim}>
                                <h1>Kon uskunalari va quvurlarning ingibitorlik himoyasi bo‘limida</h1>
                            </Col>
                            <Col lg={6} md={12} sm={12} className={styles.imageSecondBolim}>
                                <img src={bolim5}/>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar5}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> kon uskunalari va quvurlarning ingibitorlik himoyasi holatlarini</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar6}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> metallning vodorodlanish darajasini</span>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={12}>
                                <div className={styles.cardsBolimlar12}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> qo‘llanilayotgan ingibitorlarning samaradorligini aniqlash</span>
                                </div>
                            </Col>
                            
                        </Row>
                    </Container>
                    <Container fluid className={styles.secondBolim}>
                        <Row>
                            <Col lg={6} md={12} sm={12} className={styles.imageSecondBolim}>
                                <img src={bolim6}/>
                            </Col>
                            <Col lg={6} md={12} sm={12} className={styles.headingSecondBolim}>
                                <h1>Moddiy texnika va yoqilg‘i energetika resurslarini me'yorlash bo‘limida</h1>
                            </Col>
                            <Col lg={12}>
                                <div className={styles.cardsBolimlar13}>
                                    <span><CheckOutlined  style={{color:'hsl(212, 86%, 64%)',fontSize:'25px'}}/></span>
                                    <span> neft va gaz qazib oluvchi, gazni qayta ishlovchi korxonalar uchun moddiy texnika resurslari sarfi bo‘yicha hamda gaz, gaz kondensatining korxonaning o‘z ehtiyoji uchun sarflanishi va yo‘qotishlari bo‘yicha me'yorlar ishlab chiqiladi. Ushbu me'yorlar moddiy texnika resurslari sotib olishni rejalashtirishda, "O‘zneftgazqazibchiqarish" aksiyadorlik kompaniyasi bo‘yicha gaz va gaz kondensati balanslarini tuzishda qo‘llaniladi</span>
                                </div>
                            </Col>
                            
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}
