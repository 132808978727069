import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';

import styles from '../css/komp_haqida.module.css'
class AfillanganRu extends React.Component {
    

    render() {
        return (
            <div>
                <Container fluid className='mt-5 '>
                <Row>
			
					<Col lg={12}>
					<h3 className='text-center mb-5'>Список аффилированных лиц эмитента</h3>
                    <div className="blueLine"></div>
	<Table
      responsive="sm" responsive="md" responsive="lg"
       className={styles.jadval}
       style={{ background: "linearGradient(45deg, #fff, #4f1c72)"}}>
		<thead >
			<tr>
				<th rowSpan="2" style={{textAlign:'center', fontSize:'16px'}}>Полное наименование аффилированного лица - для юридических лиц
или Ф.И.О. аффилированного лица - для физических лиц</th>
				<th rowSpan="2" style={{textAlign:'center', fontSize:'18px'}}>Основание, по которому лицо является аффилированным</th>
				<th colSpan="2" style={{textAlign:'center', fontSize:'18px', lineHeight:"18px"}}>Доля в уставном капитале эмитента</th>	
			</tr>
            <tr style={{lineHeight:"11px"}}>

                <th style={{textAlign:'center'}}>кол-во (шт.)</th>
                <th style={{textAlign:'center'}}>Доля в УФ (%)</th>
            </tr>
		</thead>
		<tbody >
			
			<tr>
				<td style={{fontSize:'16px'}}>Турдибаев Алишер Абдувасикович</td>
				<td style={{textAlign:'center'}}>Председатель Наблюдательного совета</td>
				<td style={{textAlign:'center'}}>0</td>
				<td style={{textAlign:'center'}}>0</td>
		
			</tr>
			<tr>
				<td style={{fontSize:'16px'}}>Аҳметов Истам Ҳамроевич</td>
				<td style={{textAlign:'center'}}>Член Наблюдательного совета</td>
				<td style={{textAlign:'center'}}> </td>
				<td style={{textAlign:'center'}}> </td>

			</tr>
			<tr>
				<td style={{fontSize:'16px'}}>Ҳафизов Умиджон Усмонович</td>
				<td style={{textAlign:'center'}}>Член Наблюдательного совета</td>
				<td style={{textAlign:'center'}}>0</td>
				<td style={{textAlign:'center'}}>0</td>

			</tr>
			<tr>
				<td style={{fontSize:'16px'}}>Абдуллаев Тимур Русланович</td>
				<td style={{textAlign:'center'}}>Член Наблюдательного совета</td>
				<td style={{textAlign:'center'}}>0</td>
				<td style={{textAlign:'center'}}>0</td>
				
			</tr>
            <tr>
				<td style={{fontSize:'16px'}}>Собиров Бахтиёр Махмудович</td>
				<td style={{textAlign:'center'}}>Член Наблюдательного совета</td>
				<td style={{textAlign:'center'}}>0</td>
				<td style={{textAlign:'center'}}>0</td>
				
			</tr>
            <tr>
				<td style={{fontSize:'16px'}}>Солиев Элёр Элмуродович</td>
				<td style={{textAlign:'center'}}>Член Исполнительного органа</td>
				<td style={{textAlign:'center'}}>0</td>
				<td style={{textAlign:'center'}}>0</td>
				
			</tr>
            <tr>
				<td style={{fontSize:'16px'}}>Тошов Нурхон Мирзоевич</td>
				<td style={{textAlign:'center'}}>Член Исполнительного органа</td>
				<td style={{textAlign:'center'}}>0</td>
				<td style={{textAlign:'center'}}>0</td>
				
			</tr>
            <tr>
				<td style={{fontSize:'16px'}}>Хусанов Фархад Рустамович</td>
				<td style={{textAlign:'center'}}>Член Исполнительного органа</td>
				<td style={{textAlign:'center'}}>0</td>
				<td style={{textAlign:'center'}}>0</td>

			</tr>

		</tbody>
	</Table>
					</Col>
			
				</Row>

                </Container>
            </div>
        );
    }
}



export default AfillanganRu;
